export default {
  getPicAuthCode: {
    url: '/system/image_code',
    isMock: false,
    tips: '获取验证码',
  },
  systemRsa: {
    url: '/system/rsa',
    isMock: false,
    tips: '获取key',
  },
  manageLogin: {
    url: '/system/login',
    isMock: false,
    tips: '登录',
  },
  systemLoginOut: {
    url: '/system/login_out',
    isMock: false,
    tips: '退出登录',
  },
  orderLogistics: {
    url: '/order/logistics',
    isMock: false,
    tips: '物流信息新增/删除',
  },
  zoneList: {
    url: '/zone_list',
    isMock: false,
    tips: '地区下拉列表',
  },
  uploadFile: {
    url: '/supplier_factory/upload/file',
    isMock: false,
    tips: '图片上传',
  },
  kehuUploadFile: {
    url: '/supplier_factory/upload/file',
    isMock: false,
    tips: '图片上传',
  },
}
