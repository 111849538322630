export default {
  // 用户管理重制密码
  passwordReset: {
    url: '/password/reset',
    isMock: false,
    tips: '重制密码',
  },
  // 用户列表api
  permissionUser: {
    url: '/permission/user',
    isMock: false,
    tips: '获取用户列表/修改指定用户/添加用户/删除指定用户',
  },
  // 用户所属角色列表
  permissionRoleList: {
    url: '/permission/role_list',
    isMock: false,
    tips: '获取角色列表',
  },
  permissionRoleIdList: {
    url: '/permission/role_inner_func',
    isMock: false,
    tips: '获取角色的权限菜单列表id',
  },
  supplierPermissionRoleIdList: {
    url: '/permission/supplier_role_func',
    isMock: false,
    tips: '获取供应商端角色的权限菜单列表id',
  },
  permissionRightList: {
    url: '/permission/role_func',
    isMock: false,
    tips: '获取角色权限列表',
  },
  supplierPermissionRightList: {
    url: '/permission/supplier_func/rela',
    isMock: false,
    tips: '更新供应商端权限',
  },
  permissionRightMenuList: {
    url: '/permission/menu_list',
    isMock: false,
    tips: '获取菜单列表',
  },
  supplierPermissionRightMenuList: {
    url: '/permission/supplier_func',
    isMock: false,
    tips: '获取供应商菜单列表',
  },
  permissionUserRole: {
    url: '/permission/user_role',
    isMock: false,
    tips: '获取角色下所有的用户/设置角色下的用户',
  },
  permissionQueryUser: {
    url: '/permission/query_user',
    isMock: false,
    tips: '查询用户',
  },
  permissionUserid: {
    url: '/permission/user/',
    isMock: false,
    tips: '',
  },
  permissionGetUsersRoles: {
    url: '/permission/get_users_roles',
    isMock: false,
    tips: '添加用户时获取可设置角色',
  },
  permissionWareHouseList: {
    url: '/permission/warehouse_list',
    isMock: false,
    tips: '获取仓库列表',
  },
  // 供应商//所属公司下拉
  supplierList: {
    url: '/supplier_list',
    isMock: false,
    tips: '供应商下拉窗口',
  },

  // 客户管理
  hospitalManage: {
    url: '/hospital_manage',
    isMock: false,
    tips: '新增/修改 医院（营业执照）',
  },
  // 获取医院客户端
  hospitalManageHospitalInformation: {
    url: '/hospital_manage/hospital_information',
    isMock: false,
    tips: '获取医院客户端',
  },

  // 执业许可
  hospitalManageLicensing: {
    url: '/hospital_manage/licensing',
    isMock: false,
    tips: '新增/修改 医院执业许可证',
  },
  // 补充信息
  hospitalManageSupply: {
    url: '/hospital_manage/supply',
    isMock: false,
    tips: '新增/修改 医院补充信息',
  },

  // 医院审批
  hospitalManageApproval: {
    url: '/hospital_manage/approval',
    isMock: false,
    tips: '新增/修改 医院审批',
  },
  hospitalStatus: {
    url: '/hospital_status',
    isMock: false,
    tips: '修改医院状态',
  },

  // 客户名称下拉列表接口
  customerList: {
    url: '/customer_list',
    isMock: false,
    tips: '客户名称下拉接口',
  },
  statusList: {
    url: '/status_list',
    isMock: false,
    tips: '状态名称下拉接口',
  },
  publicSpecialUser: {
    url: '/public/special_user',
    isMock: false,
    tips: '特殊用户下拉',
  },
  buyerSelect: {
    url: '/gsp/warehouse_receipt/buyer_list',
    isMock: false,
    tips: '采购员下拉',
  },
  // 经销商列表处理
  // distributorManage: {
  //   url: '/distributor_manage',
  //   isMock: false,
  //   tips: '经销商列表',
  // },

  // 新增/修改 经销商（营业执照）
  distributorManage: {
    url: '/distributor_manage',
    isMock: false,
    tips: '新增/修改 经销商（营业执照）',
  },
  distributorStatus: {
    url: '/distributor_status',
    isMock: false,
    tips: '修改经销商状态',
  },
  // 经营许可
  distributorManageBusinessAllow: {
    url: '/distributor_manage/business_allow',
    isMock: false,
    tips: '经销商经营许可证',
  },

  distributorManageDistributorInformation: {
    url: '/distributor_manage/distributor_information',
    isMock: false,
    tips: '获取经销商信息（营业执照信息）',
  },
  zoneList: {
    url: '/zone_list',
    isMock: false,
    tips: '修改经销商状态',
  },

  supplierBusinessscope: {
    url: '/supplier/businessscope',
    isMock: false,
    tips: '经营范围查询',
  },

  supplierBusinessscopeList: {
    url: '/supplier/businessscope_list',
    isMock: false,
    tips: '经营范围下拉列表',
  },
  distributorManageFilingcertificate: {
    url: '/distributor_manage/filingcertificate',
    isMock: false,
    tips: '新增/修改 经销商备案凭证',
  },
  distributorManageSupply: {
    url: '/distributor_manage/supply',
    isMock: false,
    tips: '新增/修改 经销商补充信息',
  },

  distributorManageApproval: {
    url: '/distributor_manage/approval',
    isMock: false,
    tips: '新增/修改 首营审批',
  },
  // 经营端查询工厂目录
  factoryManageBusiness: {
    url: '/factory_manage/business',
    isMock: false,
    tips: '经营端查询工厂目录',
  },

  // 通过id获取工厂营业执照信息
  factoryManageFactoryIsnformation: {
    url: '/factory_manage/factory_information',
    isMock: false,
    tips: '经营端查询工厂目录',
  },

  // 新建修改 工厂营业执照
  factoryManage: {
    url: '/factory_manage',
    isMock: false,
    tips: '新建修改 工厂营业执照',
  },

  // 工厂生产许可
  factoryManageProductionlicense: {
    url: '/factory_manage/productionlicense',
    isMock: false,
    tips: '经营端查询工厂目录',
  },
  //  获取 工厂备案凭证
  factoryManageFilingcertificate: {
    url: '/factory_manage/filingcertificate',
    isMock: false,
    tips: '新建修改 工厂营业执照/添加/修改 工厂备案信息',
  },

  // 获取 提交 工厂首营审核
  factoryManageFirstmarketingapproval: {
    url: '/factory_manage/firstmarketingapproval',
    isMock: false,
    tips: '获取 工厂首营审核',
  },

  // 获取厂家拉下列表
  factoryList: {
    url: '/factory_list/business',
    isMock: false,
    tips: '获取 工厂首营审核',
  },

  statementSaleDetail: {
    url: '/statement/sale/detail',
    isMock: false,
    tips: '客户销售表列表 详细',
  },
  //
  statementSaleList: {
    url: '/statement/sale/list',
    isMock: false,
    tips: '客户销售表列表',
  },
  //
  productShow: {
    url: '/product/show',
    isMock: false,
    tips: '产品下拉',
  },

  // 厂家供货表
  factorySupply: {
    url: '/factory_supply',
    isMock: false,
    tips: '厂家供货表',
  },
  // 厂家二级列表
  factorySupplySecond: {
    url: '/factory_supply/second',
    isMock: false,
    tips: '厂家二级列表',
  },

  // 禁止操作
  factoryManageStateChange: {
    url: '/factory_manage/state_change',
    isMock: false,
    tips: '厂家管理经营端禁止',
  },
  // 境外厂家
  factoryManageFactoryInformation: {
    url: '/factory_manage/factory_information',
    isMock: false,
    tips: '厂家管理删除操作',
  },
  // 境外
  abroadFactory: {
    url: '/abroad_factory/submit',
    isMock: false,
    tips: '厂家管理删除操作',
  },
  ProductSupplyTotal: {
    url: '/product_supply/total',
    isMock: false,
    tips: '月度产品总计',
  },
  ProductSupplySecTotal: {
    url: '/product_supply/second_total',
    isMock: false,
    tips: '二级月度产品总计',
  },
  supplierMounSupplyTotal: {
    url: '/supplier_mounth_supply/total',
    isMock: false,
    tips: '月度供应商总计',
  },
  supplierMounSupplySecTotal: {
    url: '/supplier_mounth_supply/second/total',
    isMock: false,
    tips: '二级月度供应商总计',
  },
  supplierMounSupplyThiTotal: {
    url: '/supplier_mounth_supply/third/total',
    isMock: false,
    tips: '三级月度供应商总计',
  },
  // 获取供应商供货表
  supplierSupplyList: {
    url: '/supplier_supply',
    isMock: false,
    tips: '获取 供应商供货表',
  },
  // 获取月度供应商
  supplierMounthSupplyList: {
    url: '/supplier_mounth_supply',
    isMock: false,
    tips: '获取月度供应商',
  },
  // 获取月度供应商二级页面
  supplierMounthSupplyListSec: {
    url: '/supplier_mounth_supply/second',
    isMock: false,
    tips: '获取月度供应商',
  },
  // 获取月度供应商三级页面
  supplierMounthSupplyListThi: {
    url: '/supplier_mounth_supply/third',
    isMock: false,
    tips: '获取月度供应商',
  },
  supplierSupplyDetailList: {
    url: '/supplier_supply/second',
    isMock: false,
    tips: '获取 供应商供货表二级详情',
  },
  customerUsageList: {
    url: '/customer_meter',
    isMock: false,
    tips: '获取/删除 客户用量表',
  },
  customerUsageNameList: {
    url: '/customer_meter/meter_name',
    isMock: false,
    tips: '获取 客户用量表名称下拉',
  },
  customerUsageDetail: {
    url: '/customer_meter/detail',
    isMock: false,
    tips: '获取 客户用量表详情',
  },
  customerUsageStatusChange: {
    url: '/customer_meter/push',
    isMock: false,
    tips: '用量表状态推送',
  },
  customerAdd: {
    url: '/customer_meter/supplement',
    isMock: false,
    tips: '用量表状态补充',
  },
  customerTransferStatement: {
    url: '/customer_meter/commissioned',
    isMock: false,
    tips: '用量表转结算单',
  },
  outputStore: {
    url: '/customer_meter/delivery',
    isMock: false,
    tips: '用量表转出库单',
  },
  resaleOutboundOrder: {
    url: '/customer_meter/sale_delivery',
    isMock: false,
    tips: '用量表转销售出库单',
  },
  transferOrder: {
    url: '/customer_meter/transfer_order',
    isMock: false,
    tips: '用量表转单',
  },
  addLineCode: {
    url: '/product/search_barcode',
    isMock: false,
    tips: '条形码补充',
  },
  getPrinting: {
    url: '/product/dict/export',
    isMock: false,
    tips: '产品词典打印',
  },
  // 数据字典
  getDataDictionary: {
    url: '/product/dict',
    isMock: false,
    tips: '获取数据字典数据',
  },

  // 获取所有产品物料号等信息
  getProductSelectList: {
    url: '/product_select/product_list',
    isMock: false,
    tips: '获取数据字典数据',
  },
  

  setLimit: {
    url: '/product/inventory/set',
    isMock: false,
    tips: '设置数据字典物料号上下限',
  },

  // 厂家管理一键通过
  factoryOnePass: {
    url: '/factory_one/pass',
    isMock: false,
    tips: '厂家一键通过',
  },

  // 医院经销商 一键通过
  CustomerOnePass: {
    url: '/customer/one_pass',
    isMock: false,
    tips: '经销商 医院 一键通过',
  },
  // 经销商厂家管理
  factorySubmit: {
    url: '/factory/submit',
    isMock: false,
    tips: '获取厂家所有数据',
  },
  // 医院审批表
  exportHospital: {
    url: '/export_hospital',
    isMock: false,
    tips: '医院审批表',
  },
  // 经销商审批表
  exportDistributor: {
    url: '/export_distributor',
    isMock: false,
    tips: '经销商审批表',
  },
  // 经销商模版下载
  distributorTemlpateDownload: {
    url: '/distributor/temlpate_download',
    isMock: false,
    tips: '经销商模版下载',
  },
  // 经销商模版导入
  distributorImport: {
    url: '/distributor/import',
    isMock: false,
    tips: '经销商模版导入',
  },
  // 厂家导出审批表
  exportFactory: {
    url: '/export_factory',
    isMock: false,
    tips: '厂家模版导入',
  },
  // 模版打印列表,新增模版,修改模版
  templateManage: {
    url: '/template_manage',
    isMock: false,
    tips: '模版打印列表',
  },
  // 模版上传
  templateUpload: {
    url: '/template_upload',
    isMock: false,
    tips: '模版上传',
  },
  // 产品同步记录列表 手动导入
  productSynRecord: {
    url: '/product/syn/record',
    isMock: false,
    tips: '产品同步记录列表',
  },
  // 订单同步记录列表 手动导入
  orderSynRecord: {
    url: '/order/syn/record',
    isMock: false,
    tips: '订单同步记录列表',
  },
  // 订单同步记录详情
  orderSynRecordDetail: {
    url: '/order/syn/record/detail',
    isMock: false,
    tips: '订单同步记录详情',
  },
  // 新报表中心产品名称下拉
  productSkuList: {
    url: '/product_sku_list',
    isMock: false,
    tips: '新报表中心产品名称下拉',
  },
  // 供应商管理导出
  supplierlistexport: {
    url: '/supplier_list/export',
    isMock: false,
    tips: '供应商管理导出',
  },
  // 拣货单记录产品名称下拉
  warehousePickingListProduct: {
    url: '/gsp/warehouse_picking/product_list',
    isMock: false,
    tips: '拣货单记录产品名称下拉',
  },
  // 拣货单记录产品名称下拉
  warehousePickingListCustomer: {
    url: '/gsp/warehouse_picking/customer_list',
    isMock: false,
    tips: '拣货单记录产品名称下拉',
  },
  // 经营端供应商发票入库单列表
  invoiceSupplierWarehouseReceipt: {
    url: '/invoice/supplier_warehouse_receipt',
    isMock: false,
    tips: '经营端供应商发票入库单列表',
  },
  // 仓库管理详情厂家下拉
  warehouseOutDetailFactory: {
    url: '/warehouse_out/detail_factory',
    isMock: false,
    tips: '仓库管理详情厂家下拉',
  },
  // 应付管理 供应商下拉接口
  payableSupplierList: {
    url: '/payable/supplier_list',
    isMock: false,
    tips: '应付管理 供应商下拉接口',
  },
  // 校验产品规格是否使用
  productVerifyProductCode: {
    url: '/product/verify_product_code',
    isMock: false,
    tips: '校验产品规格是否使用',
  },
  // 采购申请单转采购订单
  purchaseApplyOperate: {
    url: '/purchase_apply_operate',
    isMock: false,
    tips: '采购申请单转采购订单',
  },
  // 采购申请单 转单类型 下拉
  purchaseApplySupplier: {
    url: '/purchase_apply/supplier',
    isMock: false,
    tips: '采购申请单 转单类型 下拉',
  },
  invoiceSaleList: {
    url: '/invoice/sale_list',
    isMock: false,
    tips: '采购申请单 转单类型 下拉',
  },
  warehouseDeliverNumber: {
    url: '/warehouse_deliver_number',
    isMock: false,
    tips: '出库单号列表',
  },
  supplierSupplyThrid: {
    url: '/supplier_supply/thrid',
    isMock: false,
    tips: '供应商供货表三级列表',
  },
  supplierSupplyFour: {
    url: '/supplier_mounth_supply/fourth',
    isMock: false,
    tips: '月度供应商四级页面',
  },
  productDuplication: {
    url: '/product/check',
    isMock: false,
    tips: '产品规格查询重复',
  },
  getWarehouseSelect: {
    url: '/permission/warehouse_list',
    isMock: false,
    tips: '选择仓库下拉',
  },
  getInvoiceReturngoods: {
    url: '/supplier_invoice/ct_list',
    isMock: false,
    tips: '供应商发票 关联采购退货',
  },
  getCustomerInvoice: {
    url: '/customer_invoice/xt_list',
    isMock: false,
    tips: '客户发票 关联销售退货单',
  },
  getreturnGoodsChild: {
    url: '/supplier_invoice/ctline_list',
    isMock: false,
    tips: '供应商发票 关联采购退货详情',
  },
  getWarehousingChild: {
    url: '/invoice/supplier_warehouse_receipt/detail',
    isMock: false,
    tips: '供应商发票 关联入库详情',
  },
  getSalesReturnsChild: {
    url: '/customer_invoice/xtline_list',
    isMock: false,
    tips: '客户发票 关联销售退货详情',
  },
  getOutboundorderChild: {
    url: '/invoice/warehouse_list/detail',
    isMock: false,
    tips: '客户发票 关联出库详情',
  },
  getbatchmodifyRecoil: {
    url: '/batch_modify/recoil',
    isMock: false,
    tips: '批号修改反冲',
  },
  getTransferRecoil: {
    url: '/transfer_recoil',
    isMock: false,
    tips: '调拨单反冲',
  },
  ///addPurchaseOrder
  // /purchase_order/import
  getPurchaseOrderImport: {
    url: '/purchase_order/import',
    isMock: false,
    tips: '采购新增导入',
  },
  acceptanceCodeSelect: {
    url: '/acceptance_code/select',
    isMock: false,
    tips: '报表中心规格下拉',
  },
}
