import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import api from '@/service/api/index'
import apiConsign from '@/service/api/consignmentStoreApi/index'
import http from '@/service/http'
import utils from '@/service/utils'
import moment from 'moment'
import JSEncrypt from 'jsencrypt'
import 'lib-flexible'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
// import './flexible.js'
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css'
Vue.use(UmyUi)
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$apiConsign= apiConsign
Vue.prototype.$http = http
Vue.prototype.$moment = moment
Vue.prototype.$utils = utils
Vue.use(ViewUI)
Vue.use(moment)
Vue.use(VXETable)
Vue.prototype.$getRsaCode = function (key, str) {
  // 注册方法
  let pubKey = key // ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt()
  encryptStr.setPublicKey(pubKey) // 设置 加密公钥
  let data = encryptStr.encrypt(str.toString()) // 进行加密
  return data
}

router.beforeEach((to,form,next) =>{
  if(to.meta.keepAlive && form.meta.keepAlive) {
    sessionStorage.setItem('keepalive','clearCache')
  } else {
    sessionStorage.setItem('keepalive','Cache')
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    eventHub: new Vue(), // this.$root.$emit(name, value) this.$root.eventHub.$on(name, res => {'res = value'})
  },
}).$mount('#app')
