export default {
  // 财务管理
  receivable: {
    url: '/receivable',
    isMock: false,
    tips: '应收管理',
  },
  payable: {
    url: '/payable',
    isMock: false,
    tips: '应付管理',
  },
  invoiceManage: {
    url: '/invoice_manage',
    isMock: false,
    tips: {
      get: '客户发票 发票目录/查询',
      post: '客户发票 新增发票',
      put: '客户发票 修改发票',
    },
  },
  supplierInvoice: {
    url: '/supplier_invoice',
    isMock: false,
    tips: {
      get: '供应商发票 查询',
      put: '供应商发票 修改',
      post: '供应商发票 添加',
    },
  },
  supplierInvoiceDetail: {
    url: '/supplier_invoice/detail',
    isMock: false,
    tips: '供应商发票 详情',
  },
  invoiceInformation: {
    url: '/invoice/invoice_information',
    isMock: false,
    tips: '获取发票信息',
  },
  supplierNameList: {
    url: '/supplier_list',
    isMock: false,
    tips: '供应商名称下拉',
  },
  customerList: {
    url: '/customer_list',
    isMock: false,
    tips: '客户名称下拉',
  },
  warehouseReceipt: {
    url: '/warehouse_receipt',
    isMock: false,
    tips: '入库单下拉',
  },
  purchaseOrderList: {
    url: '/purchase_order_list',
    isMock: false,
    tips: '采购单下拉',
  },
  supplierPurchaseInvoice: {
    url: '/supplier/purchase_invoice',
    isMock: false,
    tips: '采购订单/采购退货列表',
  },
  invoiceOrderReturn: {
    url: '/invoice/order_return',
    isMock: false,
    tips: '客户发票销售退货单下拉表',
  },
  dosageList: {
    url: '/usage/get',
    isMock: false,
    tips: '用量表下拉(查询条件)',
  },
  invoiceRecoil: {
    url: '/invoice/recoil',
    isMock: false,
    tips: '发票反冲',
  },

  // 报表中心
  warehouseOut: {
    url: '/warehouse_out',
    isMock: false,
    tips: '仓库出库列表',
  },
  invoiceWarehouseList: {
    url: '/invoice/warehouse_list',
    isMock: false,
    tips: '客户发票关联出库单下拉列表',
  },
  warehouseOutDetail: {
    url: '/warehouse_out/detail',
    isMock: false,
    tips: '仓库出库详情',
  },
  inventoryProductLookup: {
    url: '/inventory_product_lookup',
    isMock: false,
    tips: '仓库库存产品查询接口',
  },
  inventoryCodeLookup: {
    url: '/inventory_code_lookup',
    isMock: false,
    tips: '仓库库存产品下的规格查询',
  },
  productTrace: {
    url: '/product/trace',
    isMock: false,
    tips: '产品追溯',
  },
  productSupply: {
    url: '/product_supply',
    isMock: false,
    tips: '产品月度统计列表',
  },
  productSupplySec: {
    url: '/product_supply/second',
    isMock: false,
    tips: '产品月度统计列表',
  },
  productSupplyThrid: {
    url: '/product_supply/third',
    isMock: false,
    tips: '月度产品三级页面',
  },
  productNameList: {
    url: '/product_name/list',
    isMock: false,
    tips: '产品月度产品下拉',
  },
  productLicenceList: {
    url: '/product_licence/list',
    isMock: false,
    tips: '产品月度注册证下拉',
  },
  productTraceDetail: {
    url: '/product/trace_detail',
    isMock: false,
    tips: '产品追溯详情',
  },
  productManageSearchProduct: {
    url: '/product/manage_search_product',
    isMock: false,
    tips: '产品追溯-产品下拉',
  },
  productManageSearchProductCode: {
    url: '/product/manage_search_product_code',
    isMock: false,
    tips: '产品追溯-规格下拉',
  },

  // GSP管理
  saleOrder: {
    url: '/sale/order',
    isMock: false,
    tips: '销售订单记录',
  },
  saleOrderDetail: {
    url: '/sale/order/detail',
    isMock: false,
    tips: '销售订单详情',
  },
  gspSaleProductRecord: {
    url: '/gsp/sale/product/record',
    isMock: false,
    tips: 'GSP销售产品记录',
  },
  productShow: {
    url: '/product/show',
    isMock: false,
    tips: '产品下拉',
  },
  gspSaleReturnRecord: {
    url: '/gsp/sale/return/record',
    isMock: false,
    tips: 'GSP销售退货产品记录',
  },
  purchaseRecord: {
    url: '/gsp/purchase/product/record',
    isMock: false,
    tips: 'GSP采购产品记录',
  },
  acceptanceManage: {
    url: '/acceptance_manage',
    isMock: false,
    tips: 'GSP收货单记录',
  },
  acceptanceManageDetail: {
    url: '/acceptance_manage/detail',
    isMock: false,
    tips: 'GSP收货单记录-详情',
  },
  acceptPerson: {
    url: '/public/special_user',
    isMock: false,
    tips: '收货人下拉',
  },
  acceptProductManage: {
    url: '/acceptance_product',
    isMock: false,
    tips: 'GSP收货/验收产品记录',
  },
  purchaseReturnRecord: {
    url: '/gsp/purchase/return/record',
    isMock: false,
    tips: 'GSP采购退货产品记录',
  },
  gspPruchaseReturnProductShow: {
    url: '/gsp/pruchase_return_product/show',
    isMock: false,
    tips: 'GSP采购退货的产品下拉',
  },
  inputProductRecode: {
    url: '/gsp/warehouse_receipt/products',
    isMock: false,
    tips: 'GSP入库产品记录',
  },
  inputOrderRecode: {
    url: '/gsp/warehouse_receipt',
    isMock: false,
    tips: 'GSP入库单记录',
  },
  inputOrderRecodeDetail: {
    url: '/gsp/warehouse_receipt/detail',
    isMock: false,
    tips: 'GSP入库单-详情',
  },
  receiptSure: {
    url: '/gsp/warehouse_receipt/confirm',
    isMock: false,
    tips: 'GSP入库单确认',
  },
  receiptImport: {
    url: '/gsp/warehouse_receipt/import',
    isMock: false,
    tips: 'GSP入库单模板导入',
  },
  receiptDownload: {
    url: '/gsp/receipt_template/download',
    isMock: false,
    tips: 'GSP入库单模板下载',
  },
  warehouseReceiptRecoil: {
    url: '/warehouse_receipt_recoil',
    isMock: false,
    tips: 'GSP入库单反冲',
  },
  gspWarehousePicking: {
    url: '/gsp/warehouse_picking',
    isMock: false,
    tips: '拣货单列表',
  },
  gspWarehousePickingDetail: {
    url: '/gsp/warehouse_picking/detail',
    isMock: false,
    tips: '拣货单详情',
  },
  gspWarehousePickingProducts: {
    url: '/gsp/warehouse_picking/products',
    isMock: false,
    tips: '拣库产品记录',
  },
  pickerListist: {
    url: '/picker_list',
    isMock: false,
    tips: '拣货经手人下拉表',
  },
  warehouseDelivery: {
    url: '/warehouse_delivery',
    isMock: false,
    tips: 'GSP出库记录',
  },
  warehouseDeliveryDetail: {
    url: '/warehouse_delivery/detail',
    isMock: false,
    tips: 'GSP出库单详情',
  },
  warehouseDeliveryRecoil: {
    url: '/warehouse_delivery_recoil',
    isMock: false,
    tips: 'GSP出库单反冲',
  },
  warehouseDeliveryProduct: {
    url: '/warehouse_delivery_product',
    isMock: false,
    tips: 'GSP出库产品记录',
  },
  deliveryTemplateDownload: {
    url: '/delivery_template_download',
    isMock: false,
    tips: '出库模版下载',
  },
  deliveryTemplateImport: {
    url: '/delivery_template_import',
    isMock: false,
    tips: '出库模版读取导入',
  },
  deliveryOrderImport: {
    url: '/delivery_order_import',
    isMock: false,
    tips: '出库单确认按钮接口',
  },
  gspPurchaseProductRecordExport: {
    url: '/gsp/purchase/product/record/export',
    isMock: false,
    tips: '采购产品记录导出',
  },
  gspSaleProductRecordExport: {
    url: '/gsp/sale/product/record/export',
    isMock: false,
    tips: '销售产品记录导出',
  },
  acceptanceExport: {
    url: '/acceptance_export',
    isMock: false,
    tips: '收货验收单导出',
  },
  acceptanceBatchExport: {
    url: '/acceptance_batch_export',
    isMock: false,
    tips: '收货验收单合并导出',
  },
  acceptanceLineExport: {
    url: '/acceptance_line_export',
    isMock: false,
    tips: '收货验收产品导出',
  },
  warehouseDeliveryExport: {
    url: '/warehouse_delivery_export',
    isMock: false,
    tips: '出库单导出',
  },
  deliveryBatchExport: {
    url: '/delivery_batch_export',
    isMock: false,
    tips: '出库单合并导出',
  },
  // deliveryLineExport: {
  //   url: '/delivery_line_export',
  //   isMock: false,
  //   tips: '出库产品记录导出',
  // },
  deliveryLineExport: {
    url: '/gsp/delivery_product/export',
    isMock: false,
    tips: '出库产品记录导出',
  },
  gspWarehouseReceiptExport: {
    url: '/gsp/warehouse_receipt/export',
    isMock: false,
    tips: '入库单导出',
  },
  gspWarehouseRecrpitBatchExport: {
    url: '/gsp/warehouse_recrpit/batch_export',
    isMock: false,
    tips: '入库单批量导出',
  },
  gspWarehouseRecrpitLineExport: {
    url: '/gsp/warehouse_recrpit/line_export',
    isMock: false,
    tips: '入库单产品导出',
  },
  gspWarehousePickingExport: {
    url: '/gsp/warehouse_picking/export',
    isMock: false,
    tips: '导出拣货单',
  },
  gspWarehousePickingBatchExport: {
    url: '/gsp/warehouse_picking/batch_export',
    isMock: false,
    tips: '拣货单批量导出',
  },
  gspWarehousePickingLineExport: {
    url: '/gsp/warehouse_picking/line_export',
    isMock: false,
    tips: '拣货单产品导出',
  },
  getExportFactorySupply: {
    url: '/export/factory_supply',
    isMock: false,
    tips: '厂商供货表一级列表导出',
  },
  getExportSupplierSupply: {
    url: '/export/supplier_supply',
    isMock: false,
    tips: '导出供应商供货表',
  },
  getExportMounthSupplierSupply: {
    url: '/export/supplier_mounth_supply',
    isMock: false,
    tips: '导出供应商月度供货表',
  },
  getExportsupplier_supplySecond: {
    url: '/export/supplier_supply/second',
    isMock: false,
    tips: '导出供应商供货表二级详情',
  },
  exportsupplierMounthSupplySecond: {
    url: '/export/supplier_mounth_supply/second',
    isMock: false,
    tips: '导出供应商供货表二级详情',
  },
  exportProductSupply: {
    url: '/export/product_supply',
    isMock: false,
    tips: '产品月度统计导出',
  },
  exportProductSupplySec: {
    url: '/export/product_supply/second',
    isMock: false,
    tips: '产品月度统计详情导出',
  },
  getExportFactory_supplySecond: {
    url: '/export/factory_supply/second',
    isMock: false,
    tips: '导出厂商供货表二级',
  },
  getWarehouse_out_export: {
    url: '/warehouse_out_export',
    isMock: false,
    tips: '仓库出库列表导出接口',
  },
  getWarehouse_outDetail_export: {
    url: '/warehouse_out/detail_export',
    isMock: false,
    tips: '仓库出库详情导出',
  },
  inventory_outDetail_export: {
    url: '/inventory/detail_export',
    isMock: false,
    tips: '仓库盘存详情导出',
  },
  getStatementSaleListLxport: {
    url: '/statement/sale/list/export',
    isMock: false,
    tips: '客户销售表',
  },
  getStatementSaleDetailExport: {
    url: '/statement/sale/detail/export',
    isMock: false,
    tips: '客户销售表详情导出',
  },
  getstatementstock_sell_storagelist: {
    url: '/statement/stock_sell_storage/list/export',
    isMock: false,
    tips: '进销存表导出',
  },
  getstatementstock_sell_storagereceiptsexport: {
    url: '/statement/stock_sell_storage/receipts/export',
    isMock: false,
    tips: '进销存表详情导出',
  },
  getPublicSpecialUser: {
    url: '/public/special_user',
    isMock: false,
    tips: '销售人下拉',
  },
  getWarehouseManagementExport: {
    url: '/warehouse_management_export',
    isMock: false,
    tips: '库存盘存导出',
  },
  //
  getWarehouseDetailExport: {
    url: '/warehouse_detail_export',
    isMock: false,
    tips: '库存盘存导出二级页面',
  },
  getdeliveryLookup: {
    url: '/delivery_lookup',
    isMock: false,
    tips: '根据仓库及产品编号查找产品批号',
  },
  templateDownloadCgs: {
    url: '/purchase_template_download',
    isMock: false,
    tips: '采购订单模板下载',
  },
  templateDownloadCg: {
    url: '/permission/user/state',
    isMock: false,
    tips: '启用禁用接口',
  },
  systemWarningListExport: {
    url: '/manage/home/system_warning/list/export',
    isMock: false,
    tips: '预警警告导出',
  },
}
