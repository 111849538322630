import Vue from 'vue'
import VueRouter from 'vue-router'
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    children: [
      // 首页
      {
        path: '/main',
        name: 'main',
        component: () => import('@/views/main.vue'),
        meta: {
          title: '首页',
          keepAlive: true,
        },
      },
      // 信息管理
      {
        path: '/supplierManage',
        name: 'supplierManage',
        component: () => import('@/views/msgManage/supplierManage.vue'),
        meta: {
          title: '信息管理',
          navName: '厂家管理',
          keepAlive: true,
        },
      },
      {
        path: '/outlandManage',
        name: 'outlandManage',
        component: () => import('../views/msgManage/outlandManage.vue'),
        meta: {
          title: '信息管理',
          navName: '境外厂家管理',
        },
      },
      {
        path: '/manufacturerManage',
        name: 'manufacturerManage',
        component: () => import('@/views/msgManage/manufacturerManage/manufacturerManage.vue'),
        meta: {
          title: '信息管理',
          navName: '厂家管理',
          triName: '厂家管理详情',
        },
      },
      {
        path: '/productManage',
        name: 'productManage',
        component: () => import('@/views/msgManage/productManage.vue'),
        meta: {
          title: '信息管理',
          navName: '产品管理',
          keepAlive: true,
        },
      },
      {
        path: '/productFrame',
        name: 'productFrame',
        component: () => import('@/views/msgManage/productManageChild/productFrame.vue'),
        meta: {
          title: '信息管理',
          navName: '产品管理',
          triName: '产品管理详情',
        },
      },
      {
        path: '/clientManage',
        name: 'clientManage',
        component: () => import('@/views/msgManage/clientManage.vue'),
        meta: {
          title: '信息管理',
          navName: '客户管理',
          keepAlive: true,
        },
      },
      {
        path: '/clientManageFrame',
        name: 'clientManageFrame',
        component: () => import('@/views/msgManage/clientManageChild/clientManageFrame.vue'),
        meta: {
          title: '信息管理',
          navName: '客户管理',
          triName: '医院',
        },
      },
      {
        path: '/clientManageAgentFrame',
        name: 'clientManageAgentFrame',
        component: () => import('@/views/msgManage/clientManageAgentChild/clientManageAgentFrame.vue'),
        meta: {
          title: '信息管理',
          navName: '客户管理',
          triName: '经销商',
        },
      },
      {
        path: '/factoryManage',
        name: 'factoryManage',
        component: () => import('@/views/msgManage/factoryManage.vue'),
        meta: {
          title: '信息管理',
          navName: '供应商管理',
          keepAlive: true,
        },
      },
      {
        path: '/firstCampApproval',
        name: 'firstCampApproval',
        component: () => import('@/views/msgManage/factoryManageChild/firstCampApproval.vue'),
        meta: {
          title: '信息管理',
          navName: '供应商管理',
          triName: '供应商管理详情',
        },
      },
      {
        path: '/factoryManageFrame',
        name: 'factoryManageFrame',
        component: () => import('@/views/msgManage/factoryManageChild/factoryManageFrame.vue'),
        meta: {
          title: '信息管理',
          navName: '供应商管理',
        },
      },
      {
        path: '/SupplierEvaluationform',
        name: 'SupplierEvaluationform',
        component: () => import('@/views/msgManage/SupplierEvaluationform.vue'),
        meta: {
          title: '信息管理',
          navName: '供应商评价表',
          keepAlive: true,
        },
      },
      // 采购管理
      {
        path: '/purchaseRequisition',
        name: 'purchaseRequisition',
        component: () => import('@/views/purchaseManage/purchaseRequisition.vue'),
        meta: {
          title: '采购管理',
          navName: '采购申请单',
          keepAlive: true,
        },
      },
      {
        path: '/addPurchaseRequisition',
        name: 'addPurchaseRequisition',
        component: () => import('@/views/purchaseManage/addPurchaseRequisition.vue'),
        meta: {
          title: '采购管理',
          navName: '采购申请单',
          triName: '新增',
        },
      },
      {
        path: '/editPurchaseRequisition',
        name: 'editPurchaseRequisition',
        component: () => import('@/views/purchaseManage/editPurchaseRequisition.vue'),
        meta: {
          title: '采购管理',
          navName: '采购申请单',
          triName: '编辑',
        },
      },
      {
        path: '/purchaseOrder',
        name: 'purchaseOrder',
        component: () => import('@/views/purchaseManage/purchaseOrder.vue'),
        meta: {
          title: '采购管理',
          navName: '采购订单',
          keepAlive: true,
        },
      },
      {
        path: '/editPurchaseOrder',
        name: 'editPurchaseOrder',
        component: () => import('@/views/purchaseManage/editPurchaseOrder.vue'),
        meta: {
          title: '采购管理',
          navName: '采购订单',
          triName: '编辑',
        },
      },
      {
        path: '/addPurchaseOrder',
        name: 'addPurchaseOrder',
        component: () => import('@/views/purchaseManage/addPurchaseOrder.vue'),
        meta: {
          title: '采购管理',
          navName: '采购订单',
          triName: '新增',
        },
      },
      {
        path: '/purchaseOrderDetails',
        name: 'purchaseOrderDetails',
        component: () => import('@/views/purchaseManage/purchaseOrderDetails.vue'),
        meta: {
          title: '采购管理',
          navName: '采购订单',
          triName: '详情',
        },
      },
      {
        path: '/entrustSettlement',
        name: 'entrustSettlement',
        component: () => import('@/views/purchaseManage/entrustSettlement.vue'),
        meta: {
          title: '采购管理',
          navName: '委托代销结算单',
          keepAlive: true,
        },
      },
      {
        path: '/entrustSettlementDetails',
        name: 'entrustSettlementDetails',
        component: () => import('@/views/purchaseManage/entrustSettlementDetails.vue'),
        meta: {
          title: '采购管理',
          navName: '委托代销结算单',
          triName: '详情',
        },
      },
      {
        path: '/purchaseReturns',
        name: 'purchaseReturns',
        component: () => import('@/views/purchaseManage/purchaseReturns.vue'),
        meta: {
          title: '采购管理',
          navName: '采购退货通知单',
          keepAlive: true,
        },
      },
      {
        path: '/addPurchaseReturns',
        name: 'addPurchaseReturns',
        component: () => import('@/views/purchaseManage/addPurchaseReturns.vue'),
        meta: {
          title: '采购管理',
          navName: '采购退货通知单',
          triName: '新增',
        },
      },
      {
        path: '/editPurchaseReturn',
        name: 'editPurchaseReturn',
        component: () => import('@/views/purchaseManage/editPurchaseReturn.vue'),
        meta: {
          title: '采购管理',
          navName: '采购退货通知单',
          triName: '编辑',
        },
      },
      {
        path: '/purchaseReturnsDetails',
        name: 'purchaseReturnsDetails',
        component: () => import('@/views/purchaseManage/purchaseReturnsDetails.vue'),
        meta: {
          title: '采购管理',
          navName: '采购退货通知单',
          triName: '详情',
        },
      },
      {
        path: '/quotation',
        name: 'quotation',
        component: () => import('@/views/salesManage/quotation.vue'),
        meta: {
          title: '销售管理',
          navName: '报价单',
          keepAlive: true,
        },
      },
      {
        path: '/quotationDetails',
        name: 'quotationDetails',
        component: () => import('@/views/salesManage/quotationDetails.vue'),
        meta: {
          title: '销售管理',
          navName: '报价单',
          triName: '详情',
        },
      },
      {
        path: '/quotationAdd',
        name: 'quotationAdd',
        component: () => import('@/views/salesManage/quotationAdd.vue'),
        meta: {
          title: '销售管理',
          navName: '报价单',
          triName: '新增',
        },
      },
      {
        path: '/adjustOrder',
        name: 'adjustOrder',
        component: () => import('@/views/salesManage/adjustOrder.vue'),
        meta: {
          title: '销售管理',
          navName: '调价单',
          keepAlive: true,
        },
      },
      {
        path: '/adjustOrderAdd',
        name: 'adjustOrderAdd',
        component: () => import('@/views/salesManage/adjustOrderAdd.vue'),
        meta: {
          title: '销售管理',
          navName: '调价单',
          triName: '新增',
        },
      },
      {
        path: '/adjustOrderDetails',
        name: 'adjustOrderDetails',
        component: () => import('@/views/salesManage/adjustOrderDetails.vue'),
        meta: {
          title: '销售管理',
          navName: '调价单',
          triName: '详情',
        },
      },
      {
        path: '/salesApplication',
        name: 'salesApplication',
        component: () => import('@/views/salesManage/salesApplication.vue'),
        meta: {
          title: '销售管理',
          navName: '销售申请单',
          keepAlive: true,
        },
      },
      {
        path: '/salesApplicationEdit',
        name: 'salesApplicationEdit',
        component: () => import('@/views/salesManage/salesApplicationEdit.vue'),
        meta: {
          title: '销售管理',
          navName: '销售申请单',
          triName: '编辑',
        },
      },
      {
        path: '/salesOrder',
        name: 'salesOrder',
        component: () => import('@/views/salesManage/salesOrder.vue'),
        meta: {
          title: '销售管理',
          navName: '销售订单',
          keepAlive: true,
        },
      },
      {
        path: '/salesOrderAdd',
        name: 'salesOrderAdd',
        component: () => import('@/views/salesManage/salesOrderAdd.vue'),
        meta: {
          title: '销售管理',
          navName: '销售订单',
          triName: '新增',
        },
      },
      {
        path: '/salesOrderDetails',
        name: 'salesOrderDetails',
        component: () => import('@/views/salesManage/salesOrderDetails.vue'),
        meta: {
          title: '销售管理',
          navName: '销售订单列表',
          triName: '详情',
        },
      },
      {
        path: '/settlementOrder',
        name: 'settlementOrder',
        component: () => import('@/views/salesManage/settlementOrder.vue'),
        meta: {
          title: '销售管理',
          navName: '委托代销结算单',
          keepAlive: true,
        },
      },
      {
        path: '/settlementOrderDetails',
        name: 'settlementOrderDetails',
        component: () => import('@/views/salesManage/settlementOrderDetails.vue'),
        meta: {
          title: '销售管理',
          navName: '委托代销结算单',
          triName: '详情',
        },
      },
      {
        path: '/salesReturn',
        name: 'salesReturn',
        component: () => import('@/views/salesManage/salesReturn.vue'),
        meta: {
          title: '销售管理',
          navName: '销售退货通知单',
          keepAlive: true,
        },
      },
      {
        path: '/salesReturnEdit',
        name: 'salesReturnEdit',
        component: () => import('@/views/salesManage/salesReturnEdit.vue'),
        meta: {
          title: '销售管理',
          navName: '销售退货通知单',
        },
      },
      {
        path: '/salesReturnDetail',
        name: 'salesReturnDetail',
        component: () => import('@/views/salesManage/salesReturnDetail.vue'),
        meta: {
          title: '销售管理',
          navName: '销售退货通知单',
          triName: '详情',
        },
      },
      {
        path: '/acceptanceDetails',
        name: 'acceptanceDetails',
        component: () => import('@/views/salesManage/acceptanceDetails.vue'),
        meta: {
          title: '销售管理',
          navName: '客户收货验收明细',
          keepAlive: true,
        },
      },
      // 仓库管理
      {
        path: '/receivingAcceptance',
        name: 'receivingAcceptance',
        component: () => import('@/views/storeManage/receivingAcceptance.vue'),
        meta: {
          title: '仓库管理',
          navName: '收货验收',
          keepAlive: true,
        },
      },
      {
        path: '/enterStorage',
        name: 'enterStorage',
        component: () => import('@/views/storeManage/enterStorage.vue'),
        meta: {
          title: '仓库管理',
          navName: '入库',
          keepAlive: true,
        },
      },
      {
        path: '/orderPicking',
        name: 'orderPicking',
        component: () => import('@/views/storeManage/picking.vue'),
        meta: {
          title: '仓库管理',
          navName: '拣货',
          keepAlive: true,
        },
      },
      {
        path: '/outStorage',
        name: 'outStorage',
        component: () => import('@/views/storeManage/outStorage.vue'),
        meta: {
          title: '仓库管理',
          navName: '出库',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryManage',
        name: 'inventoryManage',
        component: () => import('@/views/storeManage/inventoryManage.vue'),
        meta: {
          title: '仓库管理',
          navName: '库存管理',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryManageDetail',
        name: 'inventoryManageDetail',
        component: () => import('@/views/storeManage/inventoryManageDetail.vue'),
        meta: {
          title: '仓库管理',
          navName: '库存管理',
          triName: '详情',
        },
      },
      {
        path: '/allocate',
        name: 'allocate',
        component: () => import('@/views/storeManage/allocate.vue'),
        meta: {
          title: '仓库管理',
          navName: '调拨',
          keepAlive: true,
        },
      },
      {
        path: '/addAllocate',
        name: 'addAllocate',
        component: () => import('@/views/storeManage/addAllocate.vue'),
        meta: {
          keepAlive: true,
          title: '仓库管理',
          navName: '新增调拨',
          // triName: '新增',
        },
      },
      {
        path: '/allocateDetail',
        name: 'allocateDetail',
        component: () => import('@/views/storeManage/allocateDetail.vue'),
        meta: {
          title: '仓库管理',
          navName: '调拨',
          triName: '详情',
        },
      },
      {
        path: '/inventoryStock',
        name: 'inventoryStock',
        component: () => import('@/views/storeManage/inventoryStock.vue'),
        meta: {
          title: '仓库管理',
          navName: '库存盘存',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryStockDetail',
        name: 'inventoryStockDetail',
        component: () => import('@/views/storeManage/inventoryStockDetail.vue'),
        meta: {
          title: '仓库管理',
          navName: '库存盘存',
          triName: '详情',
        },
      },
      {
        path: '/inventoryStockToExamins',
        name: 'inventoryStockToExamins',
        component: () => import('@/views/storeManage/inventoryStockToExamins.vue'),
        meta: {
          title: '仓库管理',
          navName: '报溢报损审核',
          triName: '审核',
        },
      },
      {
        path: '/addInventoryStock',
        name: 'addInventoryStock',
        component: () => import('@/views/storeManage/addInventoryStock.vue'),
        meta: {
          title: '仓库管理',
          navName: '库存盘存',
          triName: '新增盘存',
        },
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/storeManage/maintenance.vue'),
        meta: {
          title: '仓库管理',
          navName: '在库养护',
          keepAlive: true,
        },
      },
      {
        path: '/changeBatch',
        name: 'changeBatch',
        component: () => import('@/views/storeManage/changeBatch.vue'),
        meta: {
          title: '仓库管理',
          navName: '批号修改',
          keepAlive: true,
        },
      },
      {
        path: '/addChangeBatch',
        name: 'addChangeBatch',
        component: () => import('@/views/storeManage/addChangeBatch.vue'),
        meta: {
          title: '仓库管理',
          navName: '批号修改',
          triName: '详情',
        },
      },
      // 财务管理
      {
        path: '/receivableManage',
        name: 'receivableManage',
        component: () => import('@/views/financialManage/receivableManage.vue'),
        meta: {
          title: '财务管理',
          navName: '应收管理',
          keepAlive: true,
        },
      },
      {
        path: '/copeManage',
        name: 'copeManage',
        component: () => import('@/views/financialManage/copeManage.vue'),
        meta: {
          title: '财务管理',
          navName: '应付管理',
          keepAlive: true,
        },
      },
      {
        path: '/invoiceManage',
        name: 'invoiceManage',
        component: () => import('@/views/financialManage/invoiceManage.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          keepAlive: true,
        },
      },
      {
        path: '/editInvoices',
        name: 'editInvoices',
        component: () => import('@/views/financialManage/editInvoices.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '供应商发票',
        },
      },
      {
        path: '/choiseListTable',
        name: 'choiseListTable',
        component: () => import('@/views/financialManage/choiseListTable.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '供应商发票',
        },
      },
      {
        path: '/choiseListTableKehu',
        name: 'choiseListTableKehu',
        component: () => import('@/views/financialManage/choiseListTableKehu.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '客户发票',
        },
      },
      {
        path: '/lookListTable',
        name: 'lookListTable',
        component: () => import('@/views/financialManage/lookListTable.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '供应商发票详情',
        },
      },
      {
        path: '/lookListTableKehu',
        name: 'lookListTableKehu',
        component: () => import('@/views/financialManage/lookListTableKehu.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '客户发票详情',
        },
      },
      {
        path: '/addCustomerInvoices',
        name: 'addCustomerInvoices',
        component: () => import('@/views/financialManage/addCustomerInvoices.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
        },
      },
      {
        path: '/newZinvoice',
        name: 'newZinvoice',
        component: () => import('@/views/financialManage/newZinvoice.vue'),
        meta: {
          title: '财务管理',
          navName: '发票管理',
          triName: '新增',
        },
      },
      // 报表中心
      {
        path: '/purchaseAndSale',
        name: 'purchaseAndSale',
        component: () => import('@/views/reportCenter/purchaseAndSale.vue'),
        meta: {
          title: '报表中心',
          navName: '进销存变动表',
          keepAlive: true,
        },
      },
      {
        path: '/purchaseAndSaleFirstDetail',
        name: 'purchaseAndSaleFirstDetail',
        component: () => import('@/views/reportCenter/purchaseAndSaleFirstDetail.vue'),
        meta: {
          title: '报表中心',
          navName: '进销存变动表',
          triName: '进销存变动表详情',
        },
      },
      {
        path: '/purchaseAndSaleSecendDetail',
        name: 'purchaseAndSaleSecendDetail',
        component: () => import('@/views/reportCenter/purchaseAndSaleSecendDetail.vue'),
        meta: {
          title: '报表中心',
          navName: '进销存变动表',
          triName: '进销存变动表二级详情',
        },
      },
      {
        path: '/supplierSupplyList',
        name: 'supplierSupplyList',
        component: () => import('@/views/reportCenter/supplierSupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商供货表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsSupplierSupplyList',
        name: 'detailsSupplierSupplyList',
        component: () => import('@/views/reportCenter/detailsSupplierSupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商供货表',
          triName: '详情',
        },
      },
      {
        path: '/detailsThirdSupplierSupplyList',
        name: 'detailsThirdSupplierSupplyList',
        component: () => import('@/views/reportCenter/detailsThirdSupplierSupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商供货表',
          triName: '三级详情',
        },
      },
      {
        path: '/factorySupplyList',
        name: 'factorySupplyList',
        component: () => import('@/views/reportCenter/factorySupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '厂家供货表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsFactorySupplyList',
        name: 'detailsFactorySupplyList',
        component: () => import('@/views/reportCenter/detailsFactorySupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '厂家供货表',
          triName: '详情',
        },
      },
      {
        path: '/detailsTirdFactorySupplyList',
        name: 'detailsTirdFactorySupplyList',
        component: () => import('@/views/reportCenter/detailsTirdFactorySupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '厂家供货表',
          triName: '三级详情',
        },
      },
      {
        path: '/customerSalesTable',
        name: 'customerSalesTable',
        component: () => import('@/views/reportCenter/customerSalesTable.vue'),
        meta: {
          title: '报表中心',
          navName: '客户销售表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsCustomerSalesTable',
        name: 'detailsCustomerSalesTable',
        component: () => import('@/views/reportCenter/detailsCustomerSalesTable.vue'),
        meta: {
          title: '报表中心',
          navName: '客户销售表',
          triName: '详情',
        },
      },
      {
        path: '/warehouseOutTable',
        name: 'warehouseOutTable',
        component: () => import('@/views/reportCenter/warehouseOutTable.vue'),
        meta: {
          title: '报表中心',
          navName: '仓库出库表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseOutTable',
        name: 'detailsWarehouseOutTable',
        component: () => import('@/views/reportCenter/detailsWarehouseOutTable.vue'),
        meta: {
          title: '报表中心',
          navName: '仓库出库表',
          triName: '详情',
        },
      },
      {
        path: '/productTraceabilitySheet',
        name: 'productTraceabilitySheet',
        component: () => import('@/views/reportCenter/productTraceabilitySheet.vue'),
        meta: {
          title: '报表中心',
          navName: '产品追溯表',
          keepAlive: true,
        },
      },
      {
        path: '/goDocumentNum',
        name: 'goDocumentNum',
        component: () => import('@/views/reportCenter/goDocumentNum.vue'),
        meta: {
          title: '报表中心',
          navName: '产品追溯表单据编号',
          keepAlive: true,
        },
      },
      {
        path: '/monthlyProductStatistics',
        name: 'monthlyProductStatistics',
        component: () => import('@/views/reportCenter/monthlyProductStatistics.vue'),
        meta: {
          title: '报表中心',
          navName: '产品月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlyProductStatisticsDetail',
        name: 'monthlyProductStatisticsDetail',
        component: () => import('@/views/reportCenter/monthlyProductStatisticsDetail.vue'),
        meta: {
          title: '报表中心',
          navName: '产品月度统计详情',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyList',
        name: 'monthlySupplyList',
        component: () => import('@/views/reportCenter/monthlySupplyList.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListSec',
        name: 'monthlySupplyListSec',
        component: () => import('@/views/reportCenter/monthlySupplyListSec.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListFour',
        name: 'monthlySupplyListFour',
        component: () => import('@/views/reportCenter/monthlySupplyListFour.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListThi',
        name: 'monthlySupplyListThi',
        component: () => import('@/views/reportCenter/monthlySupplyListThi.vue'),
        meta: {
          title: '报表中心',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/customerScale',
        name: 'customerScale',
        component: () => import('@/views/reportCenter/customerScale.vue'),
        meta: {
          title: '报表中心',
          navName: '调用客户用量表',
          keepAlive: true,
        },
      },
      {
        path: '/customerScaleDetail',
        name: 'customerScaleDetail',
        component: () => import('@/views/reportCenter/customerScaleDetail.vue'),
        meta: {
          title: '报表中心',
          navName: '调用客户用量表-详情',
        },
      },
      {
        path: '/customerScaleAdd',
        name: 'customerScaleAdd',
        component: () => import('@/views/reportCenter/customerScaleAdd.vue'),
        meta: {
          title: '报表中心',
          navName: '调用客户用量表-补充',
        },
      },
      {
        path: '/dataDictionary',
        name: 'dataDictionary',
        component: () => import('@/views/reportCenter/dataDictionary.vue'),
        meta: {
          title: '报表中心',
          navName: '产品字典',
          keepAlive: true,
        },
      },
      {
        path: '/loggingPage',
        name: 'loggingPage',
        component: () => import('@/views/BaisData/loggingPage.vue'),
        meta: {
          title: '基础数据',
          navName: '日志记录',
          keepAlive: true,
        },
      },
      {
        path: '/abnormalDocument',
        name: 'abnormalDocument',
        component: () => import('@/views/BaisData/abnormalDocument.vue'),
        meta: {
          title: '基础数据',
          navName: '异常单据记录',
          keepAlive: true,
        },
      },
      // GSP管理
      {
        path: '/purchaseOrderRecord',
        name: 'purchaseOrderRecord',
        component: () => import('@/views/GSPManage/purchaseOrderRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购订单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPurchaseOrderRecord',
        name: 'detailsPurchaseOrderRecord',
        component: () => import('@/views/GSPManage/details/detailsPurchaseOrderRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购订单记录',
          triName: '详情',
        },
      },
      {
        path: '/purchaseRecord',
        name: 'purchaseRecord',
        component: () => import('@/views/GSPManage/purchaseRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/receiptRecord',
        name: 'receiptRecord',
        component: () => import('@/views/GSPManage/receiptRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '收货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsReceiptRecord',
        name: 'detailsReceiptRecord',
        component: () => import('@/views/GSPManage/details/detailsReceiptRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '收货单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordProductsReceived',
        name: 'recordProductsReceived',
        component: () => import('@/views/GSPManage/recordProductsReceived.vue'),
        meta: {
          title: 'GSP管理',
          navName: '收货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/acceptanceRecord',
        name: 'acceptanceRecord',
        component: () => import('@/views/GSPManage/acceptanceRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '验收记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsAcceptanceRecord',
        name: 'detailsAcceptanceRecord',
        component: () => import('@/views/GSPManage/details/detailsAcceptanceRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '验收记录',
          triName: '详情',
        },
      },
      {
        path: '/acceptanceProductRecord',
        name: 'acceptanceProductRecord',
        component: () => import('@/views/GSPManage/acceptanceProductRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '验收产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/warehouseEntryRecord',
        name: 'warehouseEntryRecord',
        component: () => import('@/views/GSPManage/warehouseEntryRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '入库单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseEntryRecord',
        name: 'detailsWarehouseEntryRecord',
        component: () => import('@/views/GSPManage/details/detailsWarehouseEntryRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '入库单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordIncomingProducts',
        name: 'recordIncomingProducts',
        component: () => import('@/views/GSPManage/recordIncomingProducts.vue'),
        meta: {
          title: 'GSP管理',
          navName: '入库产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/purchaseReturnRecord',
        name: 'purchaseReturnRecord',
        component: () => import('@/views/GSPManage/purchaseReturnRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购退货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPurchaseReturnRecord',
        name: 'detailsPurchaseReturnRecord',
        component: () => import('@/views/GSPManage/details/detailsPurchaseReturnRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购退货单记录',
          triName: '详情',
        },
      },
      {
        path: '/purchaseReturnedProductRecords',
        name: 'purchaseReturnedProductRecords',
        component: () => import('@/views/GSPManage/purchaseReturnedProductRecords.vue'),
        meta: {
          title: 'GSP管理',
          navName: '采购退货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/salesRecord',
        name: 'salesRecord',
        component: () => import('@/views/GSPManage/salesRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '销售产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/pickingListRecord',
        name: 'pickingListRecord',
        component: () => import('@/views/GSPManage/pickingListRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '拣货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPickingListRecord',
        name: 'detailsPickingListRecord',
        component: () => import('@/views/GSPManage/details/detailsPickingListRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '拣货单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordPickingProducts',
        name: 'recordPickingProducts',
        component: () => import('@/views/GSPManage/recordPickingProducts.vue'),
        meta: {
          title: 'GSP管理',
          navName: '拣货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/warehouseReceiptRecord',
        name: 'warehouseReceiptRecord',
        component: () => import('@/views/GSPManage/warehouseReceiptRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseReceiptRecord',
        name: 'detailsWarehouseReceiptRecord',
        component: () => import('@/views/GSPManage/details/detailsWarehouseReceiptRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库单记录',
          triName: '详情',
        },
      },
      {
        path: '/outgoingProductRecord',
        name: 'outgoingProductRecord',
        component: () => import('@/views/GSPManage/outgoingProductRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/outboundReviewListRecord',
        name: 'outboundReviewListRecord',
        component: () => import('@/views/GSPManage/outboundReviewListRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库复核单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsOutboundReviewListRecord',
        name: 'detailsOutboundReviewListRecord',
        component: () => import('@/views/GSPManage/details/detailsOutboundReviewListRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库复核单记录',
          triName: '详情',
        },
      },
      {
        path: '/outboundReviewProductRecords',
        name: 'outboundReviewProductRecords',
        component: () => import('@/views/GSPManage/outboundReviewProductRecords.vue'),
        meta: {
          title: 'GSP管理',
          navName: '出库复核产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/salesReturnRecord',
        name: 'salesReturnRecord',
        component: () => import('@/views/GSPManage/salesReturnRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '销售退货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/transferRecord',
        name: 'transferRecord',
        component: () => import('@/views/GSPManage/transferRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '调拨记录',
          keepAlive: true,
        },
      },
      {
        path: '/InventorySurplus',
        name: 'InventorySurplus',
        component: () => import('@/views/GSPManage/InventorySurplus.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘盈单',
          keepAlive: true,
        },
      },
      {
        path: '/detailsInventorySurplus',
        name: 'detailsInventorySurplus',
        component: () => import('@/views/GSPManage/details/detailsInventorySurplus.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘盈单',
          triName: '盘盈单-详情',
        },
      },
      {
        path: '/InventorySurplusRecord',
        name: 'InventorySurplusRecord',
        component: () => import('@/views/GSPManage/InventorySurplusRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘盈记录',
          keepAlive: true,
        },
      },
      {
        path: '/InventoryLoss',
        name: 'InventoryLoss',
        component: () => import('@/views/GSPManage/InventoryLoss.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘亏单',
          keepAlive: true,
        },
      },
      {
        path: '/detailsInventoryLoss',
        name: 'detailsInventoryLoss',
        component: () => import('@/views/GSPManage/details/detailsInventoryLoss.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘亏单',
          triName: '盘亏单-详情',
        },
      },
      {
        path: '/InventoryLossRecord',
        name: 'InventoryLossRecord',
        component: () => import('@/views/GSPManage/InventoryLossRecord.vue'),
        meta: {
          title: 'GSP管理',
          navName: '盘亏记录',
          keepAlive: true,
        },
      },
      // 权限管理
      {
        path: '/changePwd',
        name: 'changePwd',
        component: () => import('@/views/authManage/changePwd.vue'),
        meta: {
          title: '权限管理',
          navName: '修改密码',
          keepAlive: true,
        },
      },
      {
        path: '/roleManage',
        name: 'roleManage',
        component: () => import('@/views/authManage/roleManage.vue'),
        meta: {
          title: '权限管理',
          navName: '角色管理',
          keepAlive: true,
        },
      },
      {
        path: '/menuManage',
        name: 'menuManage',
        component: () => import('@/views/authManage/menuManage.vue'),
        meta: {
          title: '权限管理',
          navName: '菜单管理',
          keepAlive: true,
        },
      },
      {
        path: '/storeManage',
        name: 'storeManage',
        component: () => import('@/views/authManage/storeManage.vue'),
        meta: {
          title: '权限管理',
          navName: '仓库设置',
          keepAlive: true,
        },
      },
      {
        path: '/userManage',
        name: 'userManage',
        component: () => import('@/views/authManage/userManage.vue'),
        meta: {
          title: '权限管理',
          navName: '用户设置',
          keepAlive: true,
        },
      },
      {
        path: '/userRole',
        name: 'userRole',
        component: () => import('@/views/authManage/userRole.vue'),
        meta: {
          title: '权限管理',
          navName: '用户所属角色',
          keepAlive: true,
        },
      },
      {
        path: '/roleMenuAuth',
        name: 'roleMenuAuth',
        component: () => import('@/views/authManage/roleMenuAuth.vue'),
        meta: {
          title: '权限管理',
          navName: '角色菜单权限',
          keepAlive: true,
        },
      },
      // 基础数据
      {
        path: '/OrderEqualRecord',
        name: 'OrderEqualRecord',
        component: () => import('@/views/BaisData/OrderEqualRecord.vue'),
        meta: {
          title: '基础数据',
          navName: '订单同步记录',
          keepAlive: true,
        },
      },
      {
        path: '/OrderEqualRecordDetail',
        name: 'OrderEqualRecordDetail',
        component: () => import('@/views/BaisData/OrderEqualRecordDetail.vue'),
        meta: {
          title: '基础数据',
          navName: '订单同步记录',
          triName: '详情',
        },
      },
      {
        path: '/ProductEqualRecord',
        name: 'ProductEqualRecord',
        component: () => import('@/views/BaisData/ProductEqualRecord.vue'),
        meta: {
          title: '基础数据',
          navName: '产品同步记录',
          keepAlive: true,
        },
      },
      {
        path: '/PrintTemplete',
        name: 'PrintTemplete',
        component: () => import('@/views/BaisData/PrintTemplete.vue'),
        meta: {
          title: '基础数据',
          navName: '打印模版',
          keepAlive: true,
        },
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('@/views/MessageGL/notice.vue'),
        meta: {
          title: '消息管理',
          navName: '通知公告',
          keepAlive: true,
        },
      },
      {
        path: '/fileDownload',
        name: 'fileDownload',
        component: () => import('@/views/MessageGL/fileDownload.vue'),
        meta: {
          title: '消息管理',
          navName: '文件下载',
          keepAlive: true,
        },
      },

      //--------所有寄售仓页面
      {
        path: '/purchaseRequisitionConsign',
        name: 'purchaseRequisitionConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/purchaseRequisition.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购申请单',
          keepAlive: true,
        },
      },
      {
        path: '/addPurchaseRequisitionConsign',
        name: 'addPurchaseRequisitionConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/addPurchaseRequisition.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购申请单',
          triName: '新增',
        },
      },
      {
        path: '/editPurchaseRequisitionConsign',
        name: 'editPurchaseRequisitionConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/editPurchaseRequisition.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购申请单',
          triName: '编辑',
        },
      },
      {
        path: '/purchaseOrderConsign',
        name: 'purchaseOrderConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/purchaseOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单',
          keepAlive: true,
        },
      },
      {
        path: '/editPurchaseOrderConsign',
        name: 'editPurchaseOrderConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/editPurchaseOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单',
          triName: '编辑',
        },
      },
      {
        path: '/addPurchaseOrderConsign',
        name: 'addPurchaseOrderConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/addPurchaseOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单',
          triName: '新增',
        },
      },
      {
        path: '/purchaseOrderDetailsConsign',
        name: 'purchaseOrderDetailsConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/purchaseOrderDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单',
          triName: '详情',
        },
      },
      {
        path: '/entrustSettlementConsign',
        name: 'entrustSettlementConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/entrustSettlement.vue'),
        meta: {
          title: '寄售仓',
          navName: '委托代销结算单',
          keepAlive: true,
        },
      },
      {
        path: '/entrustSettlementDetailsConsign',
        name: 'entrustSettlementDetailsConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/entrustSettlementDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '委托代销结算单',
          triName: '详情',
        },
      },
      {
        path: '/purchaseReturnsConsign',
        name: 'purchaseReturnsConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/purchaseReturns.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货通知单',
          keepAlive: true,
        },
      },
      {
        path: '/addPurchaseReturnsConsign',
        name: 'addPurchaseReturnsConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/addPurchaseReturns.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货通知单',
          triName: '新增',
        },
      },
      {
        path: '/editPurchaseReturnConsign',
        name: 'editPurchaseReturnConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/editPurchaseReturn.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货通知单',
          triName: '编辑',
        },
      },
      {
        path: '/purchaseReturnsDetailsConsign',
        name: 'purchaseReturnsDetailsConsign',
        component: () => import('@/views/consignmentStore/purchaseManage/purchaseReturnsDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货通知单',
          triName: '详情',
        },
      },
      {
        path: '/quotationConsign',
        name: 'quotationConsign',
        component: () => import('@/views/consignmentStore/salesManage/quotation.vue'),
        meta: {
          title: '寄售仓',
          navName: '报价单',
          keepAlive: true,
        },
      },
      {
        path: '/quotationDetailsConsign',
        name: 'quotationDetailsConsign',
        component: () => import('@/views/consignmentStore/salesManage/quotationDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '报价单',
          triName: '详情',
        },
      },
      {
        path: '/quotationAddConsign',
        name: 'quotationAddConsign',
        component: () => import('@/views/consignmentStore/salesManage/quotationAdd.vue'),
        meta: {
          title: '寄售仓',
          navName: '报价单',
          triName: '新增',
        },
      },
      {
        path: '/adjustOrderConsign',
        name: 'adjustOrderConsign',
        component: () => import('@/views/consignmentStore/salesManage/adjustOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '调价单',
          keepAlive: true,
        },
      },
      {
        path: '/adjustOrderAddConsign',
        name: 'adjustOrderAddConsign',
        component: () => import('@/views/consignmentStore/salesManage/adjustOrderAdd.vue'),
        meta: {
          title: '寄售仓',
          navName: '调价单',
          triName: '新增',
        },
      },
      {
        path: '/adjustOrderDetailsConsign',
        name: 'adjustOrderDetailsConsign',
        component: () => import('@/views/consignmentStore/salesManage/adjustOrderDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '调价单',
          triName: '详情',
        },
      },
      {
        path: '/salesApplicationConsign',
        name: 'salesApplicationConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesApplication.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售申请单',
          keepAlive: true,
        },
      },
      {
        path: '/salesApplicationEditConsign',
        name: 'salesApplicationEditConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesApplicationEdit.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售申请单',
          triName: '编辑',
        },
      },
      {
        path: '/salesOrderConsign',
        name: 'salesOrderConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售订单',
          keepAlive: true,
        },
      },
      {
        path: '/salesOrderAddConsign',
        name: 'salesOrderAddConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesOrderAdd.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售订单',
          triName: '新增',
        },
      },
      {
        path: '/salesOrderDetailsConsign',
        name: 'salesOrderDetailsConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesOrderDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售订单列表',
          triName: '详情',
        },
      },
      {
        path: '/settlementOrderConsign',
        name: 'settlementOrderConsign',
        component: () => import('@/views/consignmentStore/salesManage/settlementOrder.vue'),
        meta: {
          title: '寄售仓',
          navName: '委托代销结算单',
          keepAlive: true,
        },
      },
      {
        path: '/settlementOrderDetailsConsign',
        name: 'settlementOrderDetailsConsign',
        component: () => import('@/views/consignmentStore/salesManage/settlementOrderDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '委托代销结算单',
          triName: '详情',
        },
      },
      {
        path: '/salesReturnConsign',
        name: 'salesReturnConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesReturn.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售退货通知单',
          keepAlive: true,
        },
      },
      {
        path: '/salesReturnEditConsign',
        name: 'salesReturnEditConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesReturnEdit.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售退货通知单',
        },
      },
      {
        path: '/salesReturnDetailConsign',
        name: 'salesReturnDetailConsign',
        component: () => import('@/views/consignmentStore/salesManage/salesReturnDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售退货通知单',
          triName: '详情',
        },
      },
      {
        path: '/acceptanceDetailsConsign',
        name: 'acceptanceDetailsConsign',
        component: () => import('@/views/consignmentStore/salesManage/acceptanceDetails.vue'),
        meta: {
          title: '寄售仓',
          navName: '客户收货验收明细',
          keepAlive: true,
        },
      },
      // 仓库管理
      {
        path: '/receivingAcceptanceConsign',
        name: 'receivingAcceptanceConsign',
        component: () => import('@/views/consignmentStore/storeManage/receivingAcceptance.vue'),
        meta: {
          title: '寄售仓',
          navName: '收货验收',
          keepAlive: true,
        },
      },
      {
        path: '/enterStorageConsign',
        name: 'enterStorageConsign',
        component: () => import('@/views/consignmentStore/storeManage/enterStorage.vue'),
        meta: {
          title: '寄售仓',
          navName: '入库',
          keepAlive: true,
        },
      },
      {
        path: '/orderPickingConsign',
        name: 'orderPickingConsign',
        component: () => import('@/views/consignmentStore/storeManage/picking.vue'),
        meta: {
          title: '寄售仓',
          navName: '拣货',
          keepAlive: true,
        },
      },
      {
        path: '/outStorageConsign',
        name: 'outStorageConsign',
        component: () => import('@/views/consignmentStore/storeManage/outStorage.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryManageConsign',
        name: 'inventoryManageConsign',
        component: () => import('@/views/consignmentStore/storeManage/inventoryManage.vue'),
        meta: {
          title: '寄售仓',
          navName: '库存管理',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryManageDetailConsign',
        name: 'inventoryManageDetailConsign',
        component: () => import('@/views/consignmentStore/storeManage/inventoryManageDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '库存管理',
          triName: '详情',
        },
      },
      {
        path: '/allocateConsign',
        name: 'allocateConsign',
        component: () => import('@/views/consignmentStore/storeManage/allocate.vue'),
        meta: {
          title: '寄售仓',
          navName: '调拨',
          keepAlive: true,
        },
      },
      {
        path: '/addAllocateConsign',
        name: 'addAllocateConsign',
        component: () => import('@/views/consignmentStore/storeManage/addAllocate.vue'),
        meta: {
          keepAlive: true,
          title: '寄售仓',
          navName: '新增调拨',
          // triName: '新增',
        },
      },
      {
        path: '/allocateDetailConsign',
        name: 'allocateDetailConsign',
        component: () => import('@/views/consignmentStore/storeManage/allocateDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '调拨',
          triName: '详情',
        },
      },
      {
        path: '/inventoryStockConsign',
        name: 'inventoryStockConsign',
        component: () => import('@/views/consignmentStore/storeManage/inventoryStock.vue'),
        meta: {
          title: '寄售仓',
          navName: '库存盘存',
          keepAlive: true,
        },
      },
      {
        path: '/inventoryStockDetailConsign',
        name: 'inventoryStockDetailConsign',
        component: () => import('@/views/consignmentStore/storeManage/inventoryStockDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '库存盘存',
          triName: '详情',
        },
      },
      {
        path: '/inventoryStockToExaminsConsign',
        name: 'inventoryStockToExaminsConsign',
        component: () => import('@/views/consignmentStore/storeManage/inventoryStockToExamins.vue'),
        meta: {
          title: '寄售仓',
          navName: '报溢报损审核',
          triName: '审核Consign',
        },
      },
      {
        path: '/addInventoryStockConsign',
        name: 'addInventoryStockConsign',
        component: () => import('@/views/consignmentStore/storeManage/addInventoryStock.vue'),
        meta: {
          title: '寄售仓',
          navName: '库存盘存',
          triName: '新增盘存',
        },
      },
      {
        path: '/maintenanceConsign',
        name: 'maintenanceConsign',
        component: () => import('@/views/consignmentStore/storeManage/maintenance.vue'),
        meta: {
          title: '寄售仓',
          navName: '在库养护',
          keepAlive: true,
        },
      },
      {
        path: '/changeBatchConsign',
        name: 'changeBatchConsign',
        component: () => import('@/views/consignmentStore/storeManage/changeBatch.vue'),
        meta: {
          title: '寄售仓',
          navName: '批号修改',
          keepAlive: true,
        },
      },
      {
        path: '/addChangeBatchConsign',
        name: 'addChangeBatchConsign',
        component: () => import('@/views/consignmentStore/storeManage/addChangeBatch.vue'),
        meta: {
          title: '寄售仓',
          navName: '批号修改',
          triName: '详情',
        },
      },
      // 报表中心
      {
        path: '/purchaseAndSaleConsign',
        name: 'purchaseAndSaleConsign',
        component: () => import('@/views/consignmentStore/reportCenter/purchaseAndSale.vue'),
        meta: {
          title: '寄售仓',
          navName: '进销存变动表',
          keepAlive: true,
        },
      },
      {
        path: '/purchaseAndSaleFirstDetailConsign',
        name: 'purchaseAndSaleFirstDetailConsign',
        component: () => import('@/views/consignmentStore/reportCenter/purchaseAndSaleFirstDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '进销存变动表',
          triName: '进销存变动表详情',
        },
      },
      {
        path: '/purchaseAndSaleSecendDetailConsign',
        name: 'purchaseAndSaleSecendDetailConsign',
        component: () => import('@/views/consignmentStore/reportCenter/purchaseAndSaleSecendDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '进销存变动表',
          triName: '进销存变动表二级详情',
        },
      },
      {
        path: '/supplierSupplyListConsign',
        name: 'supplierSupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/supplierSupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商供货表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsSupplierSupplyListConsign',
        name: 'detailsSupplierSupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsSupplierSupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商供货表',
          triName: '详情',
        },
      },
      {
        path: '/detailsThirdSupplierSupplyListConsign',
        name: 'detailsThirdSupplierSupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsThirdSupplierSupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商供货表',
          triName: '三级详情',
        },
      },
      {
        path: '/factorySupplyListConsign',
        name: 'factorySupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/factorySupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '厂家供货表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsFactorySupplyListConsign',
        name: 'detailsFactorySupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsFactorySupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '厂家供货表',
          triName: '详情',
        },
      },
      {
        path: '/detailsTirdFactorySupplyListConsign',
        name: 'detailsTirdFactorySupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsTirdFactorySupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '厂家供货表',
          triName: '三级详情',
        },
      },
      {
        path: '/customerSalesTableConsign',
        name: 'customerSalesTableConsign',
        component: () => import('@/views/consignmentStore/reportCenter/customerSalesTable.vue'),
        meta: {
          title: '寄售仓',
          navName: '客户销售表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsCustomerSalesTableConsign',
        name: 'detailsCustomerSalesTableConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsCustomerSalesTable.vue'),
        meta: {
          title: '寄售仓',
          navName: '客户销售表',
          triName: '详情',
        },
      },
      {
        path: '/warehouseOutTableConsign',
        name: 'warehouseOutTableConsign',
        component: () => import('@/views/consignmentStore/reportCenter/warehouseOutTable.vue'),
        meta: {
          title: '寄售仓',
          navName: '仓库出库表',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseOutTableConsign',
        name: 'detailsWarehouseOutTableConsign',
        component: () => import('@/views/consignmentStore/reportCenter/detailsWarehouseOutTable.vue'),
        meta: {
          title: '寄售仓',
          navName: '仓库出库表',
          triName: '详情',
        },
      },
      {
        path: '/productTraceabilitySheetConsign',
        name: 'productTraceabilitySheetConsign',
        component: () => import('@/views/consignmentStore/reportCenter/productTraceabilitySheet.vue'),
        meta: {
          title: '寄售仓',
          navName: '产品追溯表',
          keepAlive: true,
        },
      },
      {
        path: '/goDocumentNumConsign',
        name: 'goDocumentNumConsign',
        component: () => import('@/views/consignmentStore/reportCenter/goDocumentNum.vue'),
        meta: {
          title: '寄售仓',
          navName: '产品追溯表单据编号',
          keepAlive: true,
        },
      },
      {
        path: '/monthlyProductStatisticsConsign',
        name: 'monthlyProductStatisticsConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlyProductStatistics.vue'),
        meta: {
          title: '寄售仓',
          navName: '产品月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlyProductStatisticsDetailConsign',
        name: 'monthlyProductStatisticsDetailConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlyProductStatisticsDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '产品月度统计详情',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListConsign',
        name: 'monthlySupplyListConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlySupplyList.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListSecConsign',
        name: 'monthlySupplyListSecConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlySupplyListSec.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListFourConsign',
        name: 'monthlySupplyListFourConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlySupplyListFour.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/monthlySupplyListThiConsign',
        name: 'monthlySupplyListThiConsign',
        component: () => import('@/views/consignmentStore/reportCenter/monthlySupplyListThi.vue'),
        meta: {
          title: '寄售仓',
          navName: '供应商月度统计',
          keepAlive: true,
        },
      },
      {
        path: '/customerScaleConsign',
        name: 'customerScaleConsign',
        component: () => import('@/views/consignmentStore/reportCenter/customerScale.vue'),
        meta: {
          title: '寄售仓',
          navName: '调用客户用量表',
          keepAlive: true,
        },
      },
      {
        path: '/customerScaleDetailConsign',
        name: 'customerScaleDetailConsign',
        component: () => import('@/views/consignmentStore/reportCenter/customerScaleDetail.vue'),
        meta: {
          title: '寄售仓',
          navName: '调用客户用量表-详情',
        },
      },
      {
        path: '/customerScaleAddConsign',
        name: 'customerScaleAddConsign',
        component: () => import('@/views/consignmentStore/reportCenter/customerScaleAdd.vue'),
        meta: {
          title: '寄售仓',
          navName: '调用客户用量表-补充',
        },
      },
      {
        path: '/dataDictionaryConsign',
        name: 'dataDictionaryConsign',
        component: () => import('@/views/consignmentStore/reportCenter/dataDictionary.vue'),
        meta: {
          title: '寄售仓',
          navName: '产品字典',
          keepAlive: true,
        },
      },
      // GSP管理
      {
        path: '/purchaseOrderRecordConsign',
        name: 'purchaseOrderRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/purchaseOrderRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPurchaseOrderRecordConsign',
        name: 'detailsPurchaseOrderRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsPurchaseOrderRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购订单记录',
          triName: '详情',
        },
      },
      {
        path: '/purchaseRecordConsign',
        name: 'purchaseRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/purchaseRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/receiptRecordConsign',
        name: 'receiptRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/receiptRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '收货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsReceiptRecordConsign',
        name: 'detailsReceiptRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsReceiptRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '收货单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordProductsReceivedConsign',
        name: 'recordProductsReceivedConsign',
        component: () => import('@/views/consignmentStore/GSPManage/recordProductsReceived.vue'),
        meta: {
          title: '寄售仓',
          navName: '收货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/acceptanceRecordConsign',
        name: 'acceptanceRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/acceptanceRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '验收记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsAcceptanceRecordConsign',
        name: 'detailsAcceptanceRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsAcceptanceRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '验收记录',
          triName: '详情',
        },
      },
      {
        path: '/acceptanceProductRecordConsign',
        name: 'acceptanceProductRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/acceptanceProductRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '验收产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/warehouseEntryRecordConsign',
        name: 'warehouseEntryRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/warehouseEntryRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '入库单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseEntryRecordConsign',
        name: 'detailsWarehouseEntryRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsWarehouseEntryRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '入库单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordIncomingProductsConsign',
        name: 'recordIncomingProductsConsign',
        component: () => import('@/views/consignmentStore/GSPManage/recordIncomingProducts.vue'),
        meta: {
          title: '寄售仓',
          navName: '入库产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/purchaseReturnRecordConsign',
        name: 'purchaseReturnRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/purchaseReturnRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPurchaseReturnRecordConsign',
        name: 'detailsPurchaseReturnRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsPurchaseReturnRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货单记录',
          triName: '详情',
        },
      },
      {
        path: '/purchaseReturnedProductRecordsConsign',
        name: 'purchaseReturnedProductRecordsConsign',
        component: () => import('@/views/consignmentStore/GSPManage/purchaseReturnedProductRecords.vue'),
        meta: {
          title: '寄售仓',
          navName: '采购退货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/salesRecordConsign',
        name: 'salesRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/salesRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/pickingListRecordConsign',
        name: 'pickingListRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/pickingListRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '拣货单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsPickingListRecordConsign',
        name: 'detailsPickingListRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsPickingListRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '拣货单记录',
          triName: '详情',
        },
      },
      {
        path: '/recordPickingProductsConsign',
        name: 'recordPickingProductsConsign',
        component: () => import('@/views/consignmentStore/GSPManage/recordPickingProducts.vue'),
        meta: {
          title: '寄售仓',
          navName: '拣货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/warehouseReceiptRecordConsign',
        name: 'warehouseReceiptRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/warehouseReceiptRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsWarehouseReceiptRecordConsign',
        name: 'detailsWarehouseReceiptRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsWarehouseReceiptRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库单记录',
          triName: '详情',
        },
      },
      {
        path: '/outgoingProductRecordConsign',
        name: 'outgoingProductRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/outgoingProductRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/outboundReviewListRecordConsign',
        name: 'outboundReviewListRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/outboundReviewListRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库复核单记录',
          keepAlive: true,
        },
      },
      {
        path: '/detailsOutboundReviewListRecordConsign',
        name: 'detailsOutboundReviewListRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsOutboundReviewListRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库复核单记录',
          triName: '详情',
        },
      },
      {
        path: '/outboundReviewProductRecordsConsign',
        name: 'outboundReviewProductRecordsConsign',
        component: () => import('@/views/consignmentStore/GSPManage/outboundReviewProductRecords.vue'),
        meta: {
          title: '寄售仓',
          navName: '出库复核产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/salesReturnRecordConsign',
        name: 'salesReturnRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/salesReturnRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '销售退货产品记录',
          keepAlive: true,
        },
      },
      {
        path: '/transferRecordConsign',
        name: 'transferRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/transferRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '调拨记录',
          keepAlive: true,
        },
      },
      {
        path: '/InventorySurplusConsign',
        name: 'InventorySurplusConsign',
        component: () => import('@/views/consignmentStore/GSPManage/InventorySurplus.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘盈单',
          keepAlive: true,
        },
      },
      {
        path: '/detailsInventorySurplusConsign',
        name: 'detailsInventorySurplusConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsInventorySurplus.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘盈单',
          triName: '盘盈单-详情',
        },
      },
      {
        path: '/InventorySurplusRecordConsign',
        name: 'InventorySurplusRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/InventorySurplusRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘盈记录',
          keepAlive: true,
        },
      },
      {
        path: '/InventoryLossConsign',
        name: 'InventoryLossConsign',
        component: () => import('@/views/consignmentStore/GSPManage/InventoryLoss.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘亏单',
          keepAlive: true,
        },
      },
      {
        path: '/detailsInventoryLossConsign',
        name: 'detailsInventoryLossConsign',
        component: () => import('@/views/consignmentStore/GSPManage/details/detailsInventoryLoss.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘亏单',
          triName: '盘亏单-详情',
        },
      },
      {
        path: '/InventoryLossRecordConsign',
        name: 'InventoryLossRecordConsign',
        component: () => import('@/views/consignmentStore/GSPManage/InventoryLossRecord.vue'),
        meta: {
          title: '寄售仓',
          navName: '盘亏记录',
          keepAlive: true,
        },
      },
      // 权限管理
      {
        path: '/storeManageConsign',
        name: 'storeManageConsign',
        component: () => import('@/views/consignmentStore/authManage/storeManage.vue'),
        meta: {
          title: '寄售仓',
          navName: '仓库设置',
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/newLogin.vue'),
  },
  {
    path: '*',
    redirect: '/home',
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  // return
  if (to.path === '/home') {
    next({
      path: '/main',
    })
  } else {
    next()
  }
})
export default router
