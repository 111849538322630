import axios from 'axios'
import { Message, Spin } from 'view-design'
import baseUrl from './baseUrl'
import router from '../router/index'
import store from '../store/index'
axios.defaults.timeout = 1000 * 60 * 30
axios.defaults.baseURL = baseUrl.http
// console.log(axios.defaults.baseURL)
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.withCredentials = true
let loginStatu = false
function logOut(error) {
  if (loginStatu) {
    return
  }
  router.push({
    name: 'login',
  })
  Message.warning({
    closable: true,
    duration: 3,
    content: '当前用户未登录或登录失效，请重新登录 ',
  })
  loginStatu = true
  return Promise.reject(error.response.data.message)
}
/*
api: 接口地址
params: 参数
showSpin: 是否显示加载中
*/
const formatParams = data => {
  let arr = []
  for (let name in data) {
    if (data[name]) {
      arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]))
    }
  }
  return arr.join('&')
}
export function fetch(api, params = {}, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.get(url, params).then(
      res => {
        Spin.hide()
        resolve(res.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
/*
api: 接口地址
params: 参数
showSpin: 是否显示加载中
*/
export function post(api, params = {}, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      res => {
        // console.log(res)
        Spin.hide()
        resolve(res.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
export function put(api, params, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = ''
  if (params.key) {
    url = api.url + '/' + params.key
    delete params.key
  } else {
    url = api.url
  }

  return new Promise((resolve, reject) => {
    axios.put(url, params).then(
      res => {
        Spin.hide()
        if (res.data.status) {
          if (res.data.message) {
            // Message.success({
            //   background: true,
            //   content: res.data.message,
            //   closable: true,
            //   duration: 2,
            // })
          }
          resolve(res.data)
          return null
        } else if (!res.data.status) {
          // 报错
          Message.error({
            background: true,
            content: res.data.message,
            closable: true,
            duration: 2,
          })
          resolve(res.data)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export function downFile(api, params = {}, showSpin = false) {
  let url = api.url
  let type = 'blob'
  if (showSpin) {
    Spin.show()
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url + '?' + formatParams(params), {
        responseType: type,
      })
      .then(
        res => {
          Spin.hide()
          resolve(res)
        },
        err => {
          console.log(err)
          reject(err)
        }
      )
  })
}
export function get(api, params = {}, showSpin = false) {
  // if (showSpin) {
  // Spin.show()
  // store.commit('setGolbalLoading',1)
  // }
  let url = api.url
  let type
  if (url === '/order/sale_order/export') {
    type = 'blob'
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url + '?' + formatParams(params), {
        responseType: type,
      })
      .then(
        res => {
          Spin.hide()
          // console.log(res)
          let code1 = url.indexOf('rsa')
          let code2 = url.indexOf('image_code')
          if (res.data.status) {
            if (code1 === -1 && code2 === -1) {
              // Message.success({
              //   background: true,
              //   content: res.data.message,
              //   closable: true,
              //   duration: 2
              // })
            }
            if (api.url === '/order/sale_order/export') {
              Message.error({
                background: true,
                content: res.data.message,
                closable: true,
                duration: 2,
              })
            } else {
              resolve(res.data)
            }
            return null
          } else if (!res.data.status && api.url !== '/order/sale_order/export') {
            // 报错
            Message.error({
              background: true,
              content: res.data.message,
              closable: true,
              duration: 2,
            })
            // eslint-disable-next-line no-irregular-whitespace
          } else if (api.url === '/order/sale_order/export') {
            resolve(res.data)
          }
        },
        err => {
          reject(err)
        }
      )
  })
}
export function getOne(api, params = {}, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.get(url + '/' + params).then(
      res => {
        Spin.hide()
        // console.log(res)
        let code1 = url.indexOf('rsa')
        let code2 = url.indexOf('image_code')
        // console.log(code2)
        // console.log(code1)
        if (res.data.status) {
          if (code1 === -1 && code2 === -1) {
            // Message.success({
            //   background: true,
            //   content: res.data.message,
            //   closable: true,
            //   duration: 2
            // })
          }
          resolve(res.data)
          return null
        } else if (!res.data.status) {
          // 报错
          Message.error({
            background: true,
            content: res.data.message,
            closable: true,
            duration: 2,
          })
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
let noLoadList = ['stock_sell_storage/list/summation', 'hospital_manage/licensing', '/hospital_manage/supply', '/hospital_manage', '/hospital_manage/approval', '/distributor_manage', '/distributor_manage/business_allow', '/distributor_manage/filingcertificate', '/distributor_manage/supply', '/distributor_manage/approval', '/acceptance_code/select', '/acceptance_product/select', '/supplier/select']
// http request 拦截器
axios.interceptors.request.use(
  request => {
    let status = false
    for (let i = 0; i < noLoadList.length; i++) {
      if (request.url.indexOf(noLoadList[i]) > -1) {
        status = true
        break
      }
      status = true
    }
    if (status) {
      store.commit('setGolbalLoading', 0)
    } else {
      store.commit('setGolbalLoading', 1)
    }
    // if (
    //   request.url.indexOf('stock_sell_storage/list/summation') > -1 ||
    //   request.url.indexOf('hospital_manage/licensing') > -1 ||
    //   request.url.indexOf('/hospital_manage/supply') > -1 ||
    //   request.url.indexOf('/hospital_manage') > -1 ||
    //   request.url.indexOf('/hospital_manage/approval') > -1 ||
    //   request.url.indexOf('/distributor_manage') > -1 ||
    //   request.url.indexOf('/distributor_manage/business_allow') > -1 ||
    //   request.url.indexOf('/distributor_manage/filingcertificate') > -1 ||
    //   request.url.indexOf('/distributor_manage/supply') > -1 ||
    //   request.url.indexOf('/distributor_manage/approval') > -1 ||
    //   request.url.indexOf('/acceptance_code/select')
    // ) {
    //   store.commit('setGolbalLoading', 0)
    // } else {
    //   store.commit('setGolbalLoading', 1)
    // }
    let token = localStorage.getItem('adminohoqicbi==')
    if (token) {
      // 将token设置成请求头
      request.headers.Authorization = 'Bearer ' + token
    }
    // console.log(request)
    return request
  },
  err => {
    store.commit('setGolbalLoading', -1)
    // console.log(err)
    return Promise.reject(err)
  }
)
// http response 拦截器
axios.interceptors.response.use(
  response => {
    // if (
    //   response.config.url.indexOf('stock_sell_storage/list/summation') > -1 ||
    //   response.config.url.indexOf('hospital_manage/licensing') > -1 ||
    //   response.config.url.indexOf('/hospital_manage/supply') > -1 ||
    //   response.config.url.indexOf('/hospital_manage') > -1 ||
    //   response.config.url.indexOf('/hospital_manage/approval') > -1 ||
    //   response.config.url.indexOf('/distributor_manage') > -1 ||
    //   response.config.url.indexOf('/distributor_manage/business_allow') > -1 ||
    //   response.config.url.indexOf('/distributor_manage/filingcertificate') > -1 ||
    //   response.config.url.indexOf('/distributor_manage/supply') > -1 ||
    //   response.config.url.indexOf('/distributor_manage/approval') > -1 ||
    //   response.url.indexOf('/acceptance_code/select')
    // ) {
    //   store.commit('setGolbalLoading', 0)
    // } else {
    //   store.commit('setGolbalLoading', -1)
    // }
    let status = false
    for (let i = 0; i < noLoadList.length; i++) {
      if (response.config.url.indexOf(noLoadList[i]) > -1) {
        status = true
        break
      }
      status = true
    }
    if (status) {
      store.commit('setGolbalLoading', 0)
    } else {
      store.commit('setGolbalLoading', 1)
    }
    if (response.data.type == 'application/x-xlsx') {
      return response
    }
    if (response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return response
    }
    if (response.data.type == 'application/zip') {
      return response
    }
    if (response.data.status) {
      if (response.config.url === '/system/login' || response.config.url === '/static/system/login') {
        if (response.data.data.token) {
          let token = response.data.data.token.value
          localStorage.setItem('adminohoqicbi==', token)
        }
      }
      loginStatu = false
      return response
    } else if (!response.data.status) {
      Spin.hide()
      Message.error({
        background: true,
        content: response.data.message || '请求失败',
        closable: true,
        duration: 2,
      })
      return Promise.reject(response.data)
    } else if (response.status != 200) {
      Message.error({
        background: true,
        content: response,
        closable: true,
        duration: 2,
      })
      return Promise.reject(response)
    }
  },
  error => {
    store.commit('setGolbalLoading', -1)
    Spin.hide()
    if (error.response.status == 401) {
      logOut(error)
    } else if (error.response.status == 500) {
      Message.error({
        background: true,
        content: 'Internal Server Error',
        closable: true,
        duration: 2,
      })
    } else {
      if (error.response.data.message) {
        Message.error({
          background: true,
          content: error.response.data.message,
          closable: true,
          duration: 2,
        })
      }
    }
    return Promise.reject(error)
  }
)

export function Delete(api, id, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.delete(url + '/' + id).then(
      res => {
        Spin.hide()
        if (res.data.status) {
          if (res.data.message) {
            Message.success({
              background: true,
              content: res.data.message,
              closable: true,
              duration: 2,
            })
          }

          resolve(res.data)
          return null
        } else if (!res.data.status) {
          // 报错
          Message.error({
            background: true,
            content: res.data.message,
            closable: true,
            duration: 2,
          })
          resolve(res.data)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export function DeleteAll(api, params, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.delete(url + '?' + formatParams(params)).then(
      res => {
        Spin.hide()
        if (res.data.status) {
          if (res.data.message) {
            // Message.success({
            //   content: res.data.message,
            //   closable: true,
            //   duration: 2,
            // })
          }

          resolve(res.data)
          return null
        } else if (!res.data.status) {
          // 报错
          Message.error({
            content: res.data.message,
            closable: true,
            duration: 2,
          })
          resolve(res.data)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export function ListDelete(api, params = {}, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.delete(url + '?' + params.name + '=' + params.list).then(
      res => {
        Spin.hide()
        if (res.status) {
          if (res.data.message) {
            Message.success({
              background: true,
              content: res.data.message,
              closable: true,
              duration: 2,
            })
          }
          resolve(res.data)
          return null
        } else if (!res.status) {
          // 报错
          Message.error({
            background: true,
            content: res.data.msg,
            closable: true,
            duration: 2,
          })
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export function Put(api, id, params, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.put(url + '/' + id, params).then(
      res => {
        Spin.hide()
        if (res.status) {
          if (res.data.message) {
            Message.success({
              content: res.data.message,
              closable: true,
              duration: 2,
            })
          }
          resolve(res.data)
          return null
        } else if (!res.status) {
          // 报错
          if (res.data.message) {
            Message.error({
              content: res.data.message,
              closable: true,
              duration: 2,
            })
          }
        }
      },
      err => {
        Spin.hide()
        reject(err)
      }
    )
  })
}
export function putOrder(api, id, order, showSpin = false) {
  if (showSpin) {
    Spin.show()
  }
  let url = api.url
  return new Promise((resolve, reject) => {
    axios.put(url + '/' + id + '/' + order).then(
      res => {
        Spin.hide()
        if (res.status) {
          if (res.data.message) {
            Message.success({
              background: true,
              content: res.data.message,
              closable: true,
              duration: 2,
            })
          }
          resolve(res.data)
          return null
        } else if (!res.status) {
          // 报错
          Message.error({
            background: true,
            content: res.data.message,
            closable: true,
            duration: 2,
          })
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
export default {
  fetch,
  post,
  get,
  getOne,
  DeleteAll,
  Delete,
  ListDelete,
  put,
  Put,
  putOrder,
  downFile,
}
