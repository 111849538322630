export default {
  permissionResetPassword: {
    url: '/permission/reset_password',
    isMock: false,
    tips: '修改密码',
  },
  dealerUserManagePasswd: {
    url: '/password/reset',
    isMock: false,
    tips: '密码重置',
  },
  permissionRoleList: {
    url: '/permission/role_list',
    isMock: false,
    tips: '获取角色列表/添加',
  },
  permissionRole: {
    url: '/permission/role',
    isMock: false,
    tips: '修改角色',
  },
  permissionMenuList: {
    url: '/permission/menu_list',
    isMock: false,
    tips: '获取菜单列表/移动菜单',
  },
  permissionMenu: {
    url: '/permission/menu',
    isMock: false,
    tips: '删除菜单列表/修改',
  },
  permissionWarehouseList: {
    url: '/permission/warehouse_list',
    isMock: false,
    tips: '获取仓库列表/新增仓库',
  },
  permissionWarehouse: {
    url: '/permission/warehouse',
    isMock: false,
    tips: '删除/修改',
  },
  permissionWareState: {
    url: '/permission/warehouse/state',
    isMock: false,
    tips: '禁用/启用仓库',
  },
  pruchaseApplyManage: {
    url: '/pruchase_apply_manage',
    isMock: false,
    tips: '采购申请单列表',
  },
  pruchaseApplyDetail: {
    url: '/pruchase_apply_detail',
    isMock: false,
    tips: '采购申请单详情',
  },
  customerList: {
    url: '/customer_list',
    isMock: false,
    tips: '客户名称下拉选择',
  },
  supplierSelect: {
    url: '/supplier/select',
    isMock: false,
    tips: '供应商名称下拉选择',
  },
  userlogsManage: {
    url: '/userlogs_manage',
    isMock: false,
    tips: '日志记录接口',
  },
  abnormalReceipts: {
    url: '/abnormal_receipts/record',
    isMock: false,
    tips: '异常单据记录',
  },
  getSupplierList: {
    url: '/supplier/list',
    isMock: false,
    tips: '获取供应商表格数据',
  },
  getSupplierNameList: {
    url: '/supplier_list',
    isMock: false,
    tips: '获取供应商名称列表',
  },
  changeSupplierStatus: {
    url: '/supplier/status',
    isMock: false,
    tips: '修改供应商状态',
  },
  showOverview: {
    url: '/supplier/overview',
    isMock: false,
    tips: '展示供应商概览',
  },
  supplierBusinessscope: {
    url: '/supplier/businessscope',
    isMock: false,
    tips: '经营范围查询',
  },
  supplierBusinessscopeList: {
    url: '/supplier/businessscope_list',
    isMock: false,
    tips: '经营范围下拉列表',
  },
  productSelect: {
    url: '/product/select',
    isMock: false,
    tips: '供应商下的产品下拉选择',
  },
  productCodeSelect: {
    url: '/product_code/select',
    isMock: false,
    tips: '供应商下产品下所有规格型号下拉选择',
  },
  getSupplier: {
    url: '/supplier/get',
    isMock: false,
    tips: '供应商详情',
  },
  supplierSave: {
    url: '/supplier/save',
    isMock: false,
    tips: '保存供应商信息',
  },
  supplierBusinessReview: {
    url: '/supplier/business_review',
    isMock: false,
    tips: '供应商首营审批保存',
  },
  supplierOnePass: {
    url: '/supplier/one_pass',
    isMock: false,
    tips: '供应商一键通过',
  },
  exportSupplier: {
    url: '/export_supplier',
    isMock: false,
    tips: '导出供应商审批表',
  },
  getProductList: {
    url: '/product/product_review_list',
    isMock: false,
    tips: '获取产品审核列表',
  },
  getProductNameSelect: {
    url: '/product/product_review_name',
    isMock: false,
    tips: '产品名称模糊查询',
  },
  getStatusSelect: {
    url: '/product/status',
    isMock: false,
    tips: '产品状态下拉',
  },
  getFactorySelect: {
    url: '/product/product_review_factory',
    isMock: false,
    tips: '生产厂家下拉',
  },
  getRegisterSelect: {
    url: '/product/licence/choose',
    isMock: false,
    tips: '注册证下拉',
  },
  getSpecificationsSelect: {
    url: '/product/model/choose',
    isMock: false,
    tips: '规格下拉',
  },
  getSupplierSelect: {
    url: '/product/product_review_supplier',
    isMock: false,
    tips: '产品供应商下拉',
  },
  changeStatus: {
    url: '/product/update_status',
    isMock: false,
    tips: '产品禁用/启用',
  },
  productLookup: {
    url: '/consign_manage/product_lookup',
    isMock: false,
    tips: '新增采购申请单查询数据列表',
  },
  pruchaseOrderManage: {
    url: '/consign_manage/pruchase_order_manage',
    isMock: false,
    tips: '采购订单列表',
  },
  pruchaseOrderDetail: {
    url: '/consign_manage/pruchase_order_detail',
    isMock: false,
    tips: '采购订单详情',
  },
  pruchaseOrderCheck: {
    url: '/pruchase_order/check',
    isMock: false,
    tips: '采购订单审核接口',
  },
  pruchaseReturnManage: {
    url: '/consign_manage/pruchase_return_manage',
    isMock: false,
    tips: '采购退货列表',
  },
  pruchaseReturnDetail: {
    url: '/consign_manage/pruchase_return_detail',
    isMock: false,
    tips: '采购退货详情',
  },
  purchaseCommissioned: {
    url: '/purchase_commissioned',
    isMock: false,
    tips: '采购-委托代销结算列表',
  },
  purchaseCommissionedDetail: {
    url: '/purchase_commissioned/detail',
    isMock: false,
    tips: '采购-委托代销结算详情',
  },
  saleReturnProductSearch: {
    url: '/consign_manage/sale/return/product/search',
    isMock: false,
    tips: '查询销售退货产品',
  },
  saleReturnProductShow: {
    url: '/consign_manage/sale/return/product/show',
    isMock: false,
    tips: '下拉销售退货产品',
  },
  saleReturn: {
    url: '/consign_manage/sale/return',
    isMock: false,
    tips: '销售退货通知单',
  },
  saleReturnDetail: {
    url: '/consign_manage/sale/return/detail',
    isMock: false,
    tips: '销售退货详情',
  },
  saleOrderReceiveDetail: {
    url: '/sale/order/receive/detail',
    isMock: false,
    tips: '客户收货验收明细列表',
  },
  saleCommissionSalesSettlement: {
    url: '/sale/commission_sales_settlement',
    isMock: false,
    tips: '委托代销结算单列表',
  },
  saleCommissionSalesSettlementDetails: {
    url: '/sale/commission_sales_settlement/detail',
    isMock: false,
    tips: '委托代销结算单详情',
  },
  saleQuotationList: {
    url: '/sale/quotation',
    isMock: false,
    tips: '获取报价单列表',
  },
  publicSpecialUser: {
    url: '/public/special_user',
    isMock: false,
    tips: '查询经手人和审核人',
  },
  getOrderList: {
    url: '/manage/home/dashboard',
    isMock: false,
    tips: '获取订单统计, 待办事项',
  },
  getTableList: {
    url: '/manage/home/system_warning',
    isMock: false,
    tips: '获取系统预警表格列表',
  },
  getWarningDetailList: {
    url: '/manage/home/system_warning/list',
    isMock: false,
    tips: '获取系统预警表格详情列表',
  },
  getMonthlyShop: {
    url: '/manage/home/monthly_purchase',
    isMock: false,
    tips: '获取每月采购统计',
  },
  getMonthlySales: {
    url: '/manage/home/monthly_sale',
    isMock: false,
    tips: '获取每月销售统计',
  },
  productProductDetail: {
    url: '/product/product_detail',
    isMock: false,
    tips: '产品审核信息详情',
  },
  productProductReviewAll: {
    url: '/product/product_review_all',
    isMock: false,
    tips: '产品一键审核',
  },
  productProductReview: {
    url: '/product/product_review',
    isMock: false,
    tips: '产品审核',
  },
  ProductReviewFactory: {
    url: '/product/product_review_factory',
    isMock: false,
    tips: '查询产品厂家',
  },
  productSearchDept: {
    url: '/product/search_dept',
    isMock: false,
    tips: '产品临床使用科室',
  },
  saleQuotationQejectReason: {
    url: '/sale/quotation/reject_reason',
    tips: '报价单驳回原因回显',
  },
  productShow: {
    url: '/consign_manage/product/show',
    tips: '产品下拉/根据产品获取产品规格和货号',
  },
  productSearch: {
    url: '/consign_manage/product/search',
    tips: '销售管理-产品查询弹窗',
  },
  saleQuotation: {
    url: '/sale/quotation',
    tips: '新增报价单',
  },
  saleQuotationOperate: {
    url: '/sale/quotation/operate',
    tips: '审核报价单',
  },
  saleQuotationExport: {
    url: '/sale/quotation/export',
    tips: '报价单导出',
  },
  saleDadjapExportBatch: {
    url: '/sale/dadjap/export/batch',
    tips: '调价单总体导出',
  },
  quotationExportBatch: {
    url: '/sale/quotation/export/batch',
    tips: '报价单总体导出',
  },
  supplierInvoiceWarehouseReceipt: {
    url: '/supplier_invoice/warehouse_receipt_detail_list',
    tips: '供应商开发票出库单详情界面',
  },
  saleDadjapExport: {
    url: '/sale/dadjap/export',
    tips: '调价单导出',
  },
  saleDadjap: {
    url: '/sale/dadjap',
    tips: '调价单列表',
  },
  saleDadjapOperate: {
    url: '/sale/dadjap/operate',
    tips: '审核调价单',
  },
  saleDadjapRejectReason: {
    url: '/sale/dadjap/reject_reason',
    tips: '调价单驳回原因回显',
  },
  saleOrder: {
    url: '/consign_manage/sale/order',
    tips: '销售单列表',
  },
  saleOrderDetails: {
    url: '/consign_manage/sale/order/detail',
    tips: '销售订单详情',
  },
  saleOrderLogistics: {
    url: '/consign_manage/sale/order/logistics',
    tips: '物流信息新增',
  },
  saleApply: {
    url: '/sale/apply',
    tips: '销售申请单列表',
  },
  saleApplyOperate: {
    url: '/sale/apply/operate',
    tips: '销售申请详情',
  },
  // 仓库管理
  warehouseManagement: {
    url: '/consign_manage/warehouse_management',
    tips: '库存管理列表',
  },
  warehouseManagementDetail: {
    url: '/consign_manage/warehouse_management/detail',
    tips: '库存管理详情',
  },
  warehouseManagementProduct: {
    url: '/consign_manage/warehouse_management/product',
    tips: '库存产品查询',
  },
  warehousRreceipt: {
    url: '/consign_manage/warehouse_receipt',
    tips: '入库单新建（确认入库',
  },
  warehousRreceiptBatch: {
    url: '/consign_manage/warehouse_receipt_batch',
    tips: '入库单新建（确认入库',
  },
  warehousDeliveryBatch: {
    url: '/consign_manage/warehouse_delivery_batch',
    tips: '出库单新建（确认入库',
  },
  acceptanceManage: {
    url: '/consign_manage/acceptance_manage',
    tips: '收货验收列表',
  },
  acceptanceManageDetail: {
    url: '/consign_manage/acceptance_manage/detail',
    tips: '收货验收表详情',
  },
  acceptanceLine: {
    url: '/consign_manage/acceptance_line',
    tips: '收货验收表详情',
  },
  pickingLine: {
    url: '/consign_manage/picking_line',
    tips: '出货验收表详情',
  },
  getPurchaseOrder: {
    url: '/consign_manage/get_purchase_order',
    tips: '收货验收关联采购单',
  },
  pruchaseDeliveryRecord: {
    url: '/consign_manage/pruchase_delivery_record',
    tips: '收货验收关联发货单（发货单列表接口）',
  },
  // 库存盘存
  queryInventory: {
    url: '/consign_manage/inventory',
    tips: '仓库盘存列表',
  },
  queryInventoryDetail: {
    url: '/consign_manage/inventory/detail',
    tips: '仓库盘存详情',
  },
  inventoryProductLookup: {
    url: '/consign_manage/inventory_product_lookup',
    tips: '仓库盘存的查询接口',
  },
  productCodeRule: {
    url: '/product/product_code_rule',
    isMock: false,
    tips: '产品条码规则下拉',
  },
  productCodeChange: {
    url: '/product_code/change_status',
    isMock: false,
    tips: '注册证/凭证操作禁用',
  },
  getSaleOrder: {
    url: '/consign_manage/get_sale_order',
    isMock: false,
    tips: '拣货单关联销售订单',
  },
  warehousePicking: {
    url: '/consign_manage/warehouse_picking',
    isMock: false,
    tips: '拣货单新增/列表',
  },
  warehousePickingDetail: {
    url: '/consign_manage/warehouse_picking/detail',
    isMock: false,
    tips: '拣货单详情',
  },
  warehouseMaintenance: {
    url: '/consign_manage/warehouse_maintenance',
    isMock: false,
    tips: '在库养护列表',
  },
  warehouseMaintenanceDetail: {
    url: '/consign_manage/warehouse_maintenance/detail',
    isMock: false,
    tips: '在库养护详情',
  },
  batchModify: {
    url: '/consign_manage/batch_modify',
    isMock: false,
    tips: '批号修改列表',
  },
  batchModifyDetail: {
    url: '/consign_manage/batch_modify/detail',
    isMock: false,
    tips: '批号修改详情',
  },
  warehouseDelivery: {
    url: '/consign_manage/warehouse_delivery',
    isMock: false,
    tips: '出库单新增',
  },
  warehouseTransfer: {
    url: '/consign_manage/warehouse_transfer',
    isMock: false,
    tips: '调拨单列表',
  },
  warehouseTransferBackProof:{
    url: '/consign_manage/warehouse_transfer/back_proof',
    isMock: false,
    tips: '调拨单回单',
  },
  warehouseTransferDetail: {
    url: '/consign_manage/warehouse_transfer/detail',
    isMock: false,
    tips: '调拨单详情',
  },
  productReviewUser: {
    url: '/product/product_review_user',
    isMock: false,
    tips: '一键审核用户',
  },
  productSearchBarcode: {
    url: '/product/search_barcode',
    isMock: false,
    tips: '条形码查询',
  },
  inventoryCodeLookup: {
    url: '/consign_manage/inventory_code_lookup',
    isMock: false,
    tips: '仓库库存产品下的规格查询',
  },
  transferPicking: {
    url: '/transfer_picking',
    isMock: false,
    tips: '调拨自动拣货',
  },
  permissionRoleFunc: {
    url: '/permission/role_func',
    isMock: false,
    tips: '获取当前角色可显示菜单',
  },
  saleOrderExport: {
    url: '/consign_manage/sale/order/export',
    isMock: false,
    tips: '导出销售订单',
  },
  warehouseOrderExport: {
    url: '/consign_manage/inventory_export',
    isMock: false,
    tips: '仓库盘存界面导出',
  },
  pruchaseOrderExport: {
    url: '/consign_manage/pruchase_order/export',
    isMock: false,
    tips: '导出采购单',
  },
  saleApplyProductSearchByCode: {
    url: '/sale/apply/product/searchByCode',
    isMock: false,
    tips: '销售申请详情根据选择的物料号-子编号查询产品信息',
  },
  factoryListBusiness: {
    url: '/factory_list/business',
    isMock: false,
    tips: '厂家管理下拉列表',
  },
  inventoryQuery: {
    url: '/consign_manage/inventory_query',
    isMock: false,
    tips: '库存查询',
  },
  productBusinessReviewExport: {
    url: '/product/business_review_export',
    isMock: false,
    tips: '产品首营审批导出',
  },
  systemOptionItem: {
    url: '/system/option/item',
    isMock: false,
    tips: '根据类型获取项目',
  },
  productEdit: {
    url: '/product/edit',
    isMock: false,
    tips: '产品编辑',
  },
  productUnit: {
    url: '/product/unit',
    isMock: false,
    tips: '产品单位',
  },
  saleReturnRecoil: {
    url: '/consign_manage/sale/return/recoil',
    isMock: false,
    tips: '销售退货单反冲',
  },
  saleOrderRecoil: {
    url: '/consign_manage/sale/order/recoil',
    isMock: false,
    tips: '销售退货单反冲',
  },
  pruchaseOrderRecoil: {
    url: '/consign_manage/pruchase_order/recoil',
    isMock: false,
    tips: '采购订单反冲',
  },
  pruchaseOrderClose: {
    url: '/consign_manage/pruchase_order_close',
    isMock: false,
    tips: '采购订单关闭',
  },
  pruchaseReturnRecoil: {
    url: '/consign_manage/pruchase_return/recoil',
    isMock: false,
    tips: '采购退货反冲',
  },
  permissionWarehouseCharacter: {
    url: '/permission/warehouse/character',
    isMock: false,
    tips: '仓库特性下拉',
  },
  saleOrderClose: {
    url: '/consign_manage/sale/order/close',
    isMock: false,
    tips: '销售订单关闭',
  },
  templateColumn: {
    url: '/template_column',
    isMock: false,
    tips: '打印模版字段对照',
  },
  templateInfo: {
    url: '/template/info',
    isMock: false,
    tips: '回显模版类型下的模板信息',
  },
  templatePrint: {
    url: '/template/print',
    isMock: false,
    tips: '模版打印',
  },
  warehouseReceiptProductsSkuList: {
    url: '/consign_manage/gsp/warehouse_receipt/products_sku/list',
    isMock: false,
    tips: '入库产品记录下拉',
  },
  productsSku: {
    url: '/consign_manage/gsp/warehouse_delivery/products_sku/list',
    isMock: false,
    tips: '入库产品记录下拉',
  },
  supplierInvoice: {
    url: '/supplier_invoice',
    isMock: false,
    tips: '创建发票',
  },
  invoiceManage: {
    url: '/invoice_manage',
    isMock: false,
    tips: '创建客户发票',
  },
  invoiceInformation: {
    url: '/invoice/invoice_information',
    isMock: false,
    tips: '客户发票详情',
  },
  supplierInvoiceDetailLook: {
    url: '/supplier_invoice/detail',
    isMock: false,
    tips: '查看发票',
  },
  invoiceWarehouseDeliveryDetailLook: {
    url: '/invoice/warehouse_delivery_detail_list',
    isMock: false,
    tips: '查看客户发票',
  },
  acceptanceCodeSelect: {
    url: '/consign_manage/acceptance_code/select',
    isMock: false,
    tips: '收获产品记录/验收产品记录下拉',
  },
  acceptanceProductSelect: {
    url: '/consign_manage/acceptance_product/select',
    isMock: false,
    tips: 'GSP收货记录产品下拉',
  },
  supplierSupplyThrid: {
    url: '/consign_manage/supplier_supply/thrid',
    isMock: false,
    tips: '供应商厂家供货表三级列表',
  },
  statementStockSellStorageList: {
    url: '/consign_manage/statement/stock_sell_storage/list',
    isMock: false,
    tips: '进销存变动表一级页面',
  },
  statementStockSellStorageListSumm: {
    url: '/consign_manage/statement/stock_sell_storage/list/summation',
    isMock: false,
    tips: '进销存变动表一级页面的合计',
  },
  statementStockSellStorageReceipts: {
    url: '/consign_manage/statement/stock_sell_storage/receipts',
    isMock: false,
    tips: '进销存变动表二级页面',
  },
  recentTemplate: {
    url: '/template/latest',
    isMock: false,
    tips: '最近使用模板',
  },
  removeinvoice: {
    url: '/invoice/documents_delete',
    isMock: false,
    tips: '发票单据删除',
  },
  //
  warehouseTransferEdit: {
    url: '/consign_manage/warehouse_transfer',
    isMock: false,
    tips: '调拨单编辑',
  },
  deliveryProductList: {
    url: '/consign_manage/delivery_product_list',
    isMock: false,
    tips: '出库产品查询列表',
  },
  receiptProductList: {
    url: '/consign_manage/receipt_product_list',
    isMock: false,
    tips: '入库产品查询列表',
  },
  acceptanceCodeSelect: {
    url: '/consign_manage/acceptance_code/select',
    isMock: false,
    tips: '收货验收产品记录选择规格',
  },
  deliveryCodeSelect: {
    url: '/consign_manage/delivery_code/select',
    isMock: false,
    tips: '(拣货出库）供应商产品下所有规格型号下拉选择',
  },
  receiptPrductModelName: {
    url: '/consign_manage/receipt/prduct_model_name',
    isMock: false,
    tips: '(入库单界面产品名称规格型号下拉表',
  },
  gspWrToWdDetail: {
    url: '/consign_manage/gsp/wr_to_wd_detail',
    isMock: false,
    tips: '入库转出库',
  },
  gspWrToWd: {
    url: '/consign_manage/gsp/wr_to_wd',
    isMock: false,
    tips: '入库转出库确定',
  },
  pickingPrductModelNames: {
    url: '/consign_manage/picking/prduct_model_name',
    isMock: false,
    tips: '拣货产品名称规格名称下拉表',
  },
  pickingPrductModelName: {
    url: '/factory_manage/supplier',
    isMock: false,
    tips: '供应商下拉',
  },
  // /supplier_evaluation/manage
  supplierEvaluationManage: {
    url: '/supplier_evaluation/manage',
    isMock: false,
    tips: '新增供应商评价表',
  },
  supplierEvaluationSupplier_info: {
    url: '/supplier_evaluation/supplier_info',
    isMock: false,
    tips: '获取供应商编码',
  },
  supplierEvaluationUuerList: {
    url: '/supplier_evaluation/uer_list',
    isMock: false,
    tips: '用户下拉',
  },
  supplierEvaluationList: {
    url: '/supplier_evaluation/list',
    isMock: false,
    tips: '供应商评价表列表',
  },
  // /supplier_evaluation/dismiss
  supplierEvaluationAudit: {
    url: '/supplier_evaluation/audit',
    isMock: false,
    tips: '审核供应商评价表',
  },
  supplierEvaluationDismiss: {
    url: '/supplier_evaluation/dismiss',
    isMock: false,
    tips: '获取驳回原因',
  },
  // /supplier_evaluation/export
  supplierEvaluationExport: {
    url: '/supplier_evaluation/export',
    isMock: false,
    tips: '导出评价表',
  },
  // /supplier_evaluation/manage
  // supplierEvaluationManage: {
  //   url: '/supplier_evaluation/manage',
  //   isMock: false,
  //   tips: '修改评价表',
  // },
  automaticPicking: {
    url: '/consign_manage/automatic_picking',
    isMock: false,
    tips: '自动拣货',
  },
  supplierSupplyTotal: {
    url: '/consign_manage/supplier_supply/total',
    isMock: false,
    tips: '供应商供货表合计',
  },
  factorySupplyTotal: {
    url: '/consign_manage/factory_supply/total',
    isMock: false,
    tips: '厂家供货表合计',
  },
  purchaseOrderPayReport: {
    url: '/consign_manage/purchase_order/pay_report',
    isMock: false,
    tips: '采购订单生成支付报告',
  },
  // /announcement_manage
  AnnouncementManage: {
    url: '/announcement_manage',
    isMock: false,
    tips: '消息管理列表',
  },
  // /announcement_detail
  AnnouncementDetail: {
    url: '/announcement_detail',
    isMock: false,
    tips: '查看消息',
  },
  // /file_manage
  UploadFile: {
    url: '/upload_file',
    isMock: false,
    tips: '消息管理上传文件',
  },
  FileManage: {
    url: '/file_manage',
    isMock: false,
    tips: '文件列表',
  },
  // /file_detail
  FileDetail: {
    url: '/file_detail',
    isMock: false,
    tips: '文件详情',
  },
  TransferLineRecord: {
    url: '/consign_manage/transfer_line_record',
    isMock: false,
    tips: '调拨记录',
  },
  TransferLineExport: {
    url: '/consign_manage/transfer_line_export',
    isMock: false,
    tips: '调拨记录导出',
  },
  gspWarehouse_receiptCreate_by: {
    url: '/consign_manage/gsp/warehouse_receipt/create_by',
    isMock: false,
    tips: '入库人',
  },
  winOrderManage: {
    url: '/consign_manage/win_order_manage',
    isMock: false,
    tips: '盘盈单',
  },
  WinOrderDetail: {
    url: '/consign_manage/win_order_detail',
    isMock: false,
    tips: '盘盈单详情',
  },
  WinOrderRecord: {
    url: '/consign_manage/win_order_record',
    isMock: false,
    tips: '盘盈记录',
  },
  lossOrderManage: {
    url: '/consign_manage/loss_order_manage',
    isMock: false,
    tips: '盘亏单',
  },
  LossOrderDetail: {
    url: '/consign_manage/loss_order_detail',
    isMock: false,
    tips: '盘亏单详情',
  },
  LossOrderRecord: {
    url: '/consign_manage/loss_order_record',
    isMock: false,
    tips: '盘亏记录',
  },
  SpilloverOrderManage: {
    url: '/consign_manage/spillover_order_manage',
    isMock: false,
    tips: '报溢报损记录',
  },
  SpilloverOrderDetail: {
    url: '/consign_manage/spillover_order/detail',
    isMock: false,
    tips: '报溢报损记录详情',
  },
  InventoryProductRecord: {
    url: '/inventory_product_record',
    isMock: false,
    tips: '仓库所有产品查询',
  },
  InventoryCodeRecord: {
    url: '/inventory_code_record',
    isMock: false,
    tips: '仓库所有规格查询',
  },
  inventoryLook: {
    url: '/consign_manage/inventory_look',
    isMock: false,
    tips: '仓库盘存记录时间查找',
  },
  wareHouseReceipt: {
    url: '/consign_manage/warehouse_receipt',
    isMock: false,
    tips: '修改备注',
  },
  transferProduct: {
    url: '/consign_manage/transfer_product',
    isMock: false,
    tips: '调拨产品/规格',
  },
  productInventoryQuantity: {
    url: '/consign_manage/product_inventory_quantity',
    isMock: false,
    tips: '根据仓库获取库存',
  },
}
