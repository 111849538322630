/*
 *  debounce：函数防抖
 *  @params
 *         fn：要执行的函数
 *         wait：间隔等待时间
 *         immediate：在开始边界还是结束边界触发执行(true=>开始边界)
 * */
import { Message, Spin } from 'view-design'

export function debounce(fn, wait, immediate) {
  let result = null
  let timeout = null
  return function (...args) {
    let context = this
    let now = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate) result = fn.call(context, ...args)
    }, wait)
    if (now) result = fn.call(context, ...args)
    return result
  }
}
/*
 *  throttle：函数节流
 *  @params
 *         fn：要执行的函数
 *         wait：间隔等待时间
 * */
export function throttle(fn, wait) {
  let canRun = true // 通过闭包保存一个标记
  return function () {
    if (!canRun) return // 在函数开头判断标记是否为true，不为true则return
    canRun = false // 立即设置为false
    setTimeout(() => {
      // 将外部传入的函数的执行放在setTimeout中
      fn.apply(this, arguments)
      // 最后在setTimeout执行完毕后再把标记设置为true(关键)表示可以执行下一次循环了。当定时器没有执行的时候标记永远是false，在开头被return掉
      canRun = true
    }, wait)
  }
}
function onlyNumFormat(number, num = 8) {
  number = (number + '').replace(/[^0-9+.]/g, '')
  if (number.length > num) {
    number = number.substring(0, num)
  }
  return number
}
export function formatOnlyNumber(value, length) {
  let formatValue = value.replace(/[^\d]/g, '')
  if (formatValue.length > length) {
    formatValue = formatValue.substring(0, length)
  }
  return formatValue
}

/**
 * 格式化金额-千分位
 * 参数：
 *    num: Number,金额
 *    sign: 是否补 ¥
 * 返回值:
 *    123,456.78
 *    ¥-123,456.00
 * */
function formatMoney(num, sign = false) {
  if (!num && num != 0) return ''
  if (isNaN(num)) return num
  num = num * 1
  // 保留两位小数, 四舍五入
  num = num.toFixed(2)
  // 转数值型
  num = parseFloat(num)
  // 格式化为千分位
  num = num.toLocaleString()
  // 补小数位
  if (!num.includes('.')) {
    num += '.00'
  }
  if (num.split('.')[1].length == 1) {
    num += '0'
  }
  // 补 ¥
  if (sign) {
    num = '¥' + num
  }
  return num
}

/**
 * 作用：处理动态表头页面请求到的列表数据
 * 形参：
 *    that：this值
 *    listData：列表数据
 * */
function dynamicHeaderList(that, listData) {
  let list = [
    // 动态表头所有日期字段
    'production_license_valid_period', // 生产许可证有效期
    'record_valid_period', // 经营备案日期
    'business_license_valid_period', // 经营许可证有效期
    'production_record_date', // 生产备案日期
    'production_date', // 生产日期
    'valid_period', // 有效期
    'licence_valid_period', // 注册证/备案凭证有效期至
    'receive_time', // 收货时间
    'accept_time', // 验收时间
    'rec_time', // 入库时间
    'order_time', // 销售/采购退货时间
    'picking_time', // 拣货时间
    'delivery_time', // 出库时间
    'purchase_time', // 采购时间
    'insert_time', // 订单时间
    'create_time', // 出库时间
    'return_time', // 采购/销售退货时间
  ]
  let list2 = [
    // 动态表头所有金额字段
    'unit_price',
    'money',
    'amount',
    'order_price',
    'price',
    'product_total_price',
    'product_unit_price',
    'subtotal',
    'sale_unit_price',
    'sale_total_price'
  ]
  // 遍历列表数据
  for (let i = 0; i < listData.length; i++) {
    // 转日期格式
    for (let j = 0; j < list.length; j++) {
      if (listData[i][list[j]]) {
        if (list[j] == 'production_date' || list[j] == 'valid_period') {
          listData[i][list[j]] = that.$moment(listData[i][list[j]] * 1000).format('YYYY-MM-DD HH:mm:ss')
          listData[i][list[j]] = Timetransformation(listData[i][list[j]])
        } else {
          listData[i][list[j]] = that.$moment(listData[i][list[j]] * 1000).format('YYYY-MM-DD')
        }
      }
    }
    // 转金额格式
    for (let j = 0; j < list2.length; j++) {
      // 包含.或,符号
      if (/\.|,/.test(listData[i][list2[j]])) {
        listData[i][list2[j]] = '¥' + listData[i][list2[j]]
      } else {
        listData[i][list2[j]] = formatMoney(listData[i][list2[j]], true)
      }
    }
  }
  return listData
}

/**
 * 作用：手动输入日期，年月or年月日
 * 形参：
 *    data：输入的内容
 *
 * */
function dataType(data) {
  // 只能输入数字 replace(/[^\d]/g,'')
  let obj = {
    code: false,
    text: '',
  }
  if (!data) {
    obj.code = true
    obj.text = '输入日期为空'
    return obj
  }

  let reg = /^\d+$|^\d+[.]?\d+$/
  if (!reg.test(data)) {
    obj.code = true
    obj.text = '输入框不能包含特殊字符'
    return obj
  }
  // 去掉前后空格
  let dataType = data.trim().toString()
  // 转字符串
  // 只能是六位数或者八位数
  if (dataType.length != 6 && dataType.length != 8) {
    obj.code = true
    obj.text = '请输入正确的时间格式如202006或者20200625'
    return obj
  }
  // 获取输入年份
  let year = dataType.substr(0, 4)
  // 获取润年还是平年
  let ryear = Number(year) % 4 // 为0就是闰年
  // 获取输入月份
  let month = dataType.substr(4, 2)
  // 获取输入天数
  let day = dataType.substr(6, 2)
  console.log(year, ryear, month, day)
  // 月份限制
  if (month) {
    if (Number(month.substr(0, 1)) < 0 || Number(month.substr(0, 1)) > 1) {
      obj.code = true
      obj.text = '请输入正确的月份格式'
      return obj
    }
    if (Number(month.substr(0, 1)) == 0) {
      if (Number(month.substr(1, 1)) == 0) {
        obj.code = true
        obj.text = '请输入正确的月份格式'
        return obj
      }
    }
    if (Number(month.substr(0, 1)) == 1) {
      if (Number(month.substr(1, 1)) > 2) {
        obj.code = true
        obj.text = '请输入正确的月份格式'
        return obj
      }
    }
    // 日期限制
    // 一年一共有365天或者366天,平年有365天,闰年有366天,闰年每隔4年一次.
    // 平年的2月是28天,闰年2月是29天.
    // 4月、6月、9月、11月各是30天..
    // 1月、3月、5月、7月、8月、10月、12月各是31天.
    // 判断闰年还是平年
    // 没有日期不做下面判断
    if (!day) {
      obj.code = false
      return obj
    }
    let thirty = [4, 6, 9, 11]
    let thirtyOne = [1, 3, 5, 7, 8, 10, 12]
    if (Number(day) == 0) {
      obj.code = true
      obj.text = '当前月份日期输入有误'
      return obj
    }
    if (thirty.indexOf(Number(month)) != -1) {
      if (Number(day) > 30) {
        obj.code = true
        obj.text = '当前月份日期输入有误'
        return obj
      }
    } else if (thirtyOne.indexOf(Number(month)) != -1) {
      if (Number(day) > 31) {
        obj.code = true
        obj.text = '当前月份日期输入有误'
        return obj
      }
    } else if (Number(month) == 2) {
      if (!ryear) {
        if (Number(day) > 29) {
          obj.code = true
          obj.text = '当前月份日期输入有误'
          return obj
        }
      } else {
        if (Number(day) > 28) {
          obj.code = true
          obj.text = '当前月份日期输入有误'
          return obj
        }
      }
    }
    return obj
  } else {
    return obj
  }
}
/**
 * 
 * @param {*} data // 传入时间  范围 
 * @returns 
 */
    // 标注小于180天的数据
function   warningDay (data,day) {
  let times = Date.parse(new Date())
  let vptime = data * 1000
  let Range = 1000*60*60*24*day
  if(vptime - times < Range) {
    return true
  } else {
    return false
  }
}



/**
 * 作用：年月或者年月日展示
 * 形参：
 *    data：输入的内容
 *
 * */
function Timetransformation(data) {
  if (data.substring(11, 20) == '00:00:00') {
    return data.substr(0, 10)
  } else {
    return data.substr(0, 7)
  }
}
/**
 * // 手机号码输入校验 如果是移动端 input输入框设为type=number
*/
function formatPhone (phone) {
  phone = phone.replace(/[^\0-9]/g, '');
  if (!phone) return;
  let frist = phone.substring(0, 1);
  if (frist != 1) {
    return '';
  }
  if (phone.length > 11) {
    return phone.substring(0, 11);
  }
  return phone;
}
export default {
  onlyNumFormat,
  throttle,
  debounce,
  formatOnlyNumber,
  formatMoney,
  dynamicHeaderList,
  dataType,
  Timetransformation,
  warningDay,
  formatPhone
}
