import Vue from 'vue'
import Vuex from 'vuex'
let Base64 = require('js-base64').Base64
Vue.use(Vuex)
let num = 0
export default new Vuex.Store({
  state: {
    userInfo: {}, // 个人信息
    breadcrumb: [],
    clientNameList: [], // 客户名称
    supplierNameList: [], // 供应商名称
    roleMenuList: [], // 角色菜单权限
    globalLoading: '',
    homePermission: false // 首页权限
  },
  mutations: {
    setHomePermission (state, boolean) {
      state.homePermission = boolean
    },
    setUserInfo(state, obj) {
      state.userInfo = obj
    },
    setClientNameList(state, arr) {
      state.clientNameList = arr
    },
    setSupplierNameList(state, arr) {
      state.supplierNameList = arr
    },
    setBreadcrumb(state, array) {
      state.breadcrumb = array
      sessionStorage.setItem('=-dwnwnxi0=', Base64.encode(array))
      // let list = Base64.decode(sessionStorage.getItem('=-dwnwnxi0='))
      // console.log(list.split(','))
    },
    // 设置全局角色菜单权限
    setRoleMenuList(state, arr) {
      state.roleMenuList = arr
    },
    setGolbalLoading(state, loading) {
      num = num + loading
      if (num == 0) {
        state.globalLoading = false
      } else {
        state.globalLoading = true
      }
    },
    // 登录重置loading
    resetLogin(state) {
      num = 0
      state.globalLoading = false
    },
  },
  actions: {},
  modules: {},
})
